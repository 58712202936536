import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc, limit, orderBy, writeBatch } from "firebase/firestore";
import {db} from './index';
import store from "../redux/store";
import * as types from '../redux/types';

export function addActivity(text='', data={}, notify=true){
  return new Promise(async resolve => {
    let state = store.getState();
    const docRef = await addDoc(collection(db, "activity"), {
      ...data,
      userId: data.userId || state.loggedInUser.id,
      text: text,
      createdAt: Date.now(),
      notify: notify,
      unread: true
    });
    resolve()
    
  })
}

export function getMyActivity(opts){
  return new Promise(async resolve => {
    store.dispatch({type: types.FETCHING_ACTIVITY, payload: true });
    const loggedInUser = store.getState().loggedInUser;
    const q = query(collection(db, "activity"), where("userId", "==", loggedInUser.id), limit(40), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    let activity = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      activity.push({
        ...doc.data(),
        id: doc.id
      })
    });
    activity = activity.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    let numberUnread = 0;
    activity.forEach(a => {
      if(a.unread && a.notify) numberUnread++;
    })
    resolve({list: activity, unread: numberUnread})
  })
}

export function activityMarkAllRead(){
  return new Promise(async resolve => {
    const loggedInUser = store.getState().loggedInUser;
    const q = query(collection(db, "activity"), where("userId", "==", loggedInUser.id), where('unread', '==', true));
    const querySnapshot = await getDocs(q);
    let ids = [];
    querySnapshot.forEach((doc) => {
      ids.push(doc.id)
    });
    const batch = writeBatch(db);
    ids.forEach(id => {
      const docRef = doc(db, "activity", id);
      batch.update(docRef, {unread: false});
    });
    await batch.commit();
    resolve();
  })
}