import { Button, message } from 'antd';
import React, { Component } from 'react';
import AdminPageTemplate from '../../../components/AdminPageTemplate';
import Input from '../../../components/inputs/Input';
import PageTitle from '../../../components/PageTitle';
import api from '../../../firebase/api';
const defaultState = {
  email: '',
  amazonUsername: '',
  password: '',
  submitting: false
};

class AdminAddUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  onChangeEmail = e => {
    this.setState({email: e.target.value})
  }

  onChagneAmazonUsername = e => {
    this.setState({amazonUsername: e.target.value})
  }

  onChangePassword = e => {
    this.setState({password: e.target.value})
  }

  onSubmit = () => {
    if(this.state.password.length<6) return alert("Please use a longer password")
    this.setState({submitting: true});
    api(`/adminCreateUser`, {
      body: {
        email: this.state.email,
        password: this.state.password,
        amazonUsername: this.state.amazonUsername
      }
    }).then(res => {
      this.setState(defaultState);
      message.success("User created!")
    })
  }

  render() {
    const { email, amazonUsername, password, submitting } = this.state;
    return(
      <AdminPageTemplate maxWidth={600}>
        <PageTitle title='Add User' />
        <div style={{height: 15}} />
        <Input inline label='Email' onChange={this.onChangeEmail} value={email} />
        <Input inline label='Amazon Username' onChange={this.onChagneAmazonUsername} value={amazonUsername} />
        <Input inline label='Password' onChange={this.onChangePassword} value={password} />
        <Button style={{marginTop: 30, width: 130}} type='primary' size='large' onClick={this.onSubmit} disabled={submitting} loading={submitting}>Submit</Button>
      </AdminPageTemplate>
    );
  }
}

const styles = {

};

export default AdminAddUserPage;