import React, { Component } from "react";
import Search from "../../components/inputs/Search";
import LoadingComponent from "../../components/LoadingComponent";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import IllustrationMessage from "../MyAssignmentsPage/IllustrationMessage";
import MyUploadBox from "./MyUploadBox";
import illustration from "../../assets/img/illustrations/upload.svg";
import { Button } from "antd";
import { withRouter } from "../../helpers/withRouter";
import { getMyUploads } from "../../firebase/books";
import { setMyUploads } from "../../redux/functions";
import { connect } from "react-redux";

class MyUploadsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    getMyUploads().then((res) => {
      setMyUploads({ list: res.list });
    });
  };

  onChangeSearch = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  onClickBook = (book, opts = {}) => {
    this.props.navigate(`/myuploads/${book.id}${opts.getReviews ? "?getreviews=true" : ""}`);
  };

  onClickUpload = () => {
    this.props.navigate(`/myuploads/new`);
  };

  render() {
    let uploads = this.props.myUploads.list;
    const loading = this.props.myUploads.loading;

    const noUploads = !this.state.loading && this.props.myUploads.list.length === 0;

    uploads = uploads.filter((item) => {
      if (this.state.searchText) {
        let searchText = this.state.searchText.toLowerCase().trim();
        if (item.title.toLowerCase().includes(searchText)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    const content = (
      <>
        <div style={{ height: 15 }} />
        {uploads.length > 0 || this.state.searchText ? 
          <Search width={300} onChange={this.onChangeSearch} value={this.state.searchText} />
        :null}
        <div style={styles.bookRow}>
          {uploads.map((b) => {
            return (
              <MyUploadBox
                canShowGetReviews={this.props.loggedInUser.points > b.points}
                onClick={(opts) => this.onClickBook(b, opts)}
                book={b}
                key={b.id + "myupbox"}
              />
            );
          })}
        </div>
      </>
    );
    return (
      <PageTemplate maxWidth={1000}>
        {/* <PageTitle title={`My Uploads (${uploads.length})`} /> */}
        <div style={styles.top}>
          {!loading && (uploads.length > 0 || this.state.searchText) ? <PageTitle title="My Uploaded Books" /> : <div style={{ flex: 1 }} />}
          <Button onClick={this.onClickUpload} type='primary'>+ Upload Book</Button>
        </div>
        <div style={styles.body}>
          {loading ? <LoadingComponent /> : null}
          {!loading && !noUploads ? content : null}
          {noUploads ? (
            <IllustrationMessage
              title={`No Uploads`}
              desc={`Click the button above to upload your first book, and get reviews!`}
              illustration={illustration}
            />
          ) : null}
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  bookRow: {
    // width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10,
    overflow: "auto",
    paddingRight: 10,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    minHeight: "min-content",
    overflow: "hidden",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    width: "100%",
  },
};

const mapStateToProps = (state) => ({
  myUploads: state.myUploads,
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(MyUploadsPage));
