import { InfoCircleOutlined } from "@ant-design/icons";
import { Input as AntInput, Tooltip } from "antd";
import React, { Component } from "react";
import colors from "../../colors";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    const { stateKey, parent, onChangeText, onChange } = this.props;
    if (stateKey && parent) {
      parent.setState({ [stateKey]: e.target.value });
    } else if (onChangeText) {
      onChangeText(e.target.value);
    } else {
      onChange(e);
    }
  };

  render() {
    let { label, value, onChangeText, inputProps, marginBottom, fullWidth, darkLabel, inline, children, error, stateKey, parent, tooltip } =
      this.props;
    if (inline) darkLabel = true;
    if (stateKey && parent) value = parent.state[stateKey];

    const styles = {
      wrapper: {
        marginBottom: marginBottom || 10,
        width: fullWidth ? "100%" : undefined,
        display: "flex",
        flexDirection: inline ? "row" : "column",
        alignItems: inline ? "center" : undefined,
      },
      input: {},
      label: {
        color: darkLabel ? colors.text : colors.lightText,
        marginRight: inline ? 10 : 0,
        width: inline ? 180 : undefined,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        lineHeight: 1.2
      },
      inputWrapper: {
        flex: 1,
        minHeight: 32,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      infoWrapper: {
        marginRight: 5,
      },
    };

    return (
      <div style={styles.wrapper}>
        {label ? (
          <div style={styles.label}>
            {tooltip ? (
              <div style={styles.infoWrapper}>
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ) : null}
            {label}
          </div>
        ) : null}
        <div style={styles.inputWrapper}>
          {children ? (
            children
          ) : (
            <AntInput status={error ? "error" : undefined} style={styles.input} {...inputProps} value={value} onChange={this.onChange} />
          )}
        </div>
      </div>
    );
  }
}

export default Input;
