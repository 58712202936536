import { Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../assets/img/logo_header.png';
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth();

class SubscriptionCover extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onLogout = () => {
    signOut(auth).then((res) => {
      // done
    });
  }


  render() {
    const { loggedInUser } = this.props;
    const { subscriptionDisabled, subscriptionExpiresAt } = loggedInUser;
    let show = false;
    if(subscriptionDisabled) show = true;
    if(subscriptionExpiresAt){
      if(subscriptionExpiresAt < Date.now()) show = true;
    } 
    if(!loggedInUser.id) show = false;
    if(!show) return null;
    if(loggedInUser.isAdmin) show = false;

    return(
      <div style={styles.wrapper}>
        <img src={logo} style={styles.logo} />
        <div style={styles.text}>Your subscription has expired</div>
        <div>Account: {loggedInUser.email}</div>
        <div style={styles.buttonRow}>
          <Button onClick={this.onLogout}>Log Out</Button>
          <div style={{width: 25}} />
          <a href='https://bookgiveaway.com'>
            <Button>Re-Subscribe</Button>
          </a>
        </div>
        <div style={{height: 100}} />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'fixed',
    zIndex: 99999,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    flexDirection: 'column',

  },
  buttonRow: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: 30,
    marginBottom: 30
  },
  text: {
    fontSize: 22
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SubscriptionCover);