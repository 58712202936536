import React from 'react';
import quoteIcon from '../../assets/img/quote.png';
import colors from '../../colors';

const BookDescription = ({description, authorName}) => {
  // if(!description) return <div />;
  if(!description) description = 'We hope you enjoy the book!';
  return(
    <div style={styles.wrapper}>
      <div style={styles.inside}>
        <img src={quoteIcon} style={styles.quoteIcon} />
        <div style={styles.description}>{description}</div>
        {/* <div style={styles.authorName}>- {authorName}</div> */}
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: 350,
    paddingLeft: 10,
    // backgroundColor: 'red',
    // minWidth: 300
  },
  inside: {
    padding: 20,
    borderRadius: 8,
    backgroundColor: colors.lightBG,
    flex: 1
  },
  quoteIcon: {
    height: 22,
    opacity: .5
  },
  description: {
    marginTop: 8,
    marginBottom: 12,
    fontWeight: '300'
  },
  authorName: {
    fontWeight: '600'
  }
};

export default BookDescription;