import React from "react";

import dollarIcon from "../../assets/img/dollar.png";
import personIcon from "../../assets/img/person.png";
import emailIcon from "../../assets/img/email.png";
import colors from "../../colors";

const sections = {
  account: {
    icon: personIcon,
    label: "Account",
  },
  billing: {
    icon: dollarIcon,
    label: "Billing",
  },
  contact: {
    icon: emailIcon,
    label: "Contact Us",
  },
};

const LeftButton = ({ onClick, section, activeSection }) => {
  let sectionData = sections[section];
  let selected = activeSection === section;

  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: selected ? colors.main : "transparent",
      marginBottom: 20,
      borderRadius: 5,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 5,
      paddingBottom: 5,
      cursor: 'pointer',
      width: 220,
    },
    label: {
      flex: 1,
      color: selected ? "#fff" : colors.text,
      fontSize: 20,
      fontWeight: "600",
    },
    icon: {
      width: 24,
      filter: selected ? "invert(1)" : undefined,
      marginRight: 12
    },
  };

  return (
    <div style={styles.wrapper} onClick={() => onClick(section)}>
      <img src={sectionData.icon} style={styles.icon} />
      <div style={styles.label}>{sectionData.label}</div>
    </div>
  );
};

export default LeftButton;
