import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleActivitySlideout } from "../../redux/functions";
import closeIcon from "../../assets/img/close.png";
import ActivityItem from "./ActivityItem";
import { activityMarkAllRead, getMyActivity } from "../../firebase/activity";
import { setMyActivity } from "../../redux/functions/activity";

class ActivitySlideout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localUnreadIds: []
    };
  }

  componentDidMount(){
    this.load()
  }

  load = () => {
    if(!this.props.activity.isFetching && this.props.loggedInUser.id){
      getMyActivity().then(res => {
        setMyActivity(res);
      });
    }
  }

  firebase_markAllRead = () => {
    activityMarkAllRead().then(res => {
      this.load();
    })
  }

  local_markAllRead = () => {
    this.setState({localUnreadIds: []})
  }

  addToLocalUnread = () => {
    let newIds = [...this.state.localUnreadIds];
    this.props.activity.list.forEach(item => {
      if(newIds.indexOf(item.id) < 0 && item.unread && item.notify) newIds.push(item.id)
    })
    this.setState({localUnreadIds: newIds});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.activity.isOpen && this.props.activity.isOpen) {
      this.setState({ renderBackdrop: true });
      setTimeout(() => {
        this.setState({ backdropAnimation: true });
      }, 80);
      this.firebase_markAllRead();
    }

    if (prevProps.activity.isOpen && !this.props.activity.isOpen) {
      this.setState({ backdropAnimation: false });
      setTimeout(() => {
        this.setState({ renderBackdrop: false });
      }, 205);
      this.local_markAllRead();
    }

    if(prevProps.activity.isFetching && !this.props.activity.isFetching){
      this.addToLocalUnread();
    }
  }

  close = () => {
    toggleActivitySlideout(false);
  };

  render() {
    const { isOpen } = this.props.activity;
    const { renderBackdrop, backdropAnimation } = this.state;

    const styles = {
      wrapper: {
        width: 450,
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        marginRight: isOpen ? 0 : -440,
        transition: ".3s",
        height: "100vh",
        backgroundColor: "#fff",
        boxShadow: "0 0 9px 3px rgba(0,0,0,.05)",
        zIndex: 3,
        padding: 10,
        paddingRight: 0,
        paddingTop: 75,
        display: 'flex',
        flexDirection: 'column',
      },
      bottom: {
        flex: 1,
        // height: '100%',
        overflowY: 'auto',
        paddingRight: 10
      },
      backdrop: {
        transition: ".2s",
        opacity: backdropAnimation ? 0.5 : 0,
        backgroundColor: "#000000",
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2,
      },
      title: {
        fontWeight: "600",
        fontSize: 20,
        marginBottom: 20,
      },
      top: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        paddingRight: 20,
        paddingLeft: 10,
      },
      closeButton: {
        padding: 4,
        cursor: "pointer",
      },
      closeIcon: {
        height: 16,
      },
    };

    return (
      <>
        {renderBackdrop ? <div style={styles.backdrop} onClick={this.close} /> : null}
        <div style={styles.wrapper}>
          <div style={styles.top}>
            <div style={styles.title}>Activity</div>
            <div style={styles.closeButton} onClick={this.close}>
              <img src={closeIcon} style={styles.closeIcon} />
            </div>
          </div>
          <div style={styles.bottom}>
            {this.props.activity.list.map(a => {
              return(
                <ActivityItem item={a} isUnread={this.state.localUnreadIds.indexOf(a.id)>-1 ? true : false}/>
              )
            })}

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: state.activity,
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(ActivitySlideout);
