import React, { Component } from "react";
import PageTemplate from "../../components/PageTemplate";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle";
import IllustrationMessage from "./IllustrationMessage";
import { ClipLoader } from "react-spinners";
import colors from "../../colors";
import LoadingComponent from "../../components/LoadingComponent";
import MyAssignmentBox from "./MyAssignmentBox";
import { withRouter } from "../../helpers/withRouter";
import { Button, Radio, Select } from "antd";
import illustration from "../../assets/img/illustrations/reading.svg";
import { getMyAssignments } from "../../firebase/assignments";
import { setMyAssignments } from "../../redux/functions";

class MyAssignmentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "active",
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    getMyAssignments().then((res) => {
      setMyAssignments(res);
    });
  };

  onClickAssignment = (a) => {
    this.props.navigate(`/assignment/${a.id}`);
  };

  onClickNewAssignment = () => {
    this.props.navigate(`/library`);
  };

  onChangeFilter = (x) => {
    this.setState({ filter: x });
  };

  render() {
    const noActiveAssignments = this.props.myAssignments.list.length === 0 && !this.props.myAssignments.loading;
    const noAssignments = noActiveAssignments && this.props.myAssignments.completedList.length === 0;
    const listToUse = this.state.filter === 'complete' ? this.props.myAssignments.completedList : this.props.myAssignments.list;


    return (
      <PageTemplate loading={this.props.myAssignments.loading} maxWidth={1000}>
        <div style={styles.top}>
          {noAssignments ? <div /> : 
          <>
            <div style={styles.pageTitleWrapper}>
              <PageTitle title="My Assignments" />
              <Select style={{ marginLeft: 30, width: 210 }} value={this.state.filter} onChange={this.onChangeFilter}>
                <Select.Option value="active">Active Assignments</Select.Option>
                <Select.Option value="complete">Complete Assignments</Select.Option>
              </Select>
            </div>
          </>
          }
          <Button onClick={this.onClickNewAssignment} type='primary'>+ New Assignment</Button>
        </div>
        {noActiveAssignments && this.state.filter === 'active' ? (
          <IllustrationMessage
            title={`No Reading Assignments`}
            desc={`Click the button above to get started reviewing books, and earning points!`}
            illustration={illustration}
          />
        ) : null}

        <div style={styles.booksRow}>
          {listToUse.map((a) => {
            return <MyAssignmentBox book={a.book} assignment={a} onClick={() => this.onClickAssignment(a)} key={a.id+'ak'} />;
          })}
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  booksRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    overflowY: "auto",
    marginTop: 5,
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  pageTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  myAssignments: state.myAssignments,
});
const mapActionsToProps = {};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(MyAssignmentsPage));
