import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Radio, Table, Modal, Input as AntInput, Tooltip, message, Tag, Alert } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import colors from "../../colors";
import BookBox from "../../components/BookBox";
import GetReviewsModal from "../../components/modals/GetReviewsModal";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import { editBookMessage, getBook } from "../../firebase/books";
import { withRouter } from "../../helpers/withRouter";
import ReviewBox from "./ReviewBox";
import { connect } from "react-redux";
import { getCompletedReviewsForBook, getPendingReviews } from "../../firebase/reviews";
import api from "../../firebase/api";
import { getPointsTransactions } from "../../firebase/pointsTransactions";
import { setPointsTransactions, updateLoggedInUser } from "../../redux/functions";
import { getMyUser } from "../../firebase/users";

const columns = [
  {
    title: "Reviewer",
    dataIndex: "assignedUserId",
    key: "assignedUserId",
    render: (data) => <div>{data ? <strong>Reviewer Assigned</strong> : "None Assigned Yet"}</div>,
  },
  {
    title: "Started Reading On",
    dataIndex: "assignedAt",
    key: "assignedAt",
    render: (data) => <div>{data ? dayjs(new Date(data)).format("MMM DD, YYYY") : '-'}</div>,
  },
  {
    title: "",
    dataIndex: "assignedAt",
    key: "assignedAt",
    render: (data) => {
      if(data){
        return <Tag color={colors.warning}>In Progress</Tag>;
      }else{
        return <Tag color={colors.lightBG}>Not Assigned Yet</Tag>;
        
      }
    },
  },
];

const initialState = {
  reviewType: "complete",
  isGetReviewsModalVisible: false,
  onboardingTooltipVisible: false,
  savingMessage: false,
  messageSaved: false,
  loading: true,
  pendingReviewsLoading: true,
  book: {},
  pendingReviews: [],
  completedReviews: []
};

class ViewMyUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    setTimeout(() => {
      this.tryShowOnboarding();
    }, 4000);
    this.load();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.params.id !== this.props.params.id){
      this.setState(initialState, () => this.load())
    }
  }

  load = () => {
    Promise.all([
      getBook(this.props.params.id),
      getPendingReviews(this.props.params.id),
      getCompletedReviewsForBook(this.props.params.id)
    ]).then(res => {
      let bookRes = res[0];
      let pendingRes = res[1];
      let completedReviewsRes = res[2];

      this.setState({
        loading: false,
        book: bookRes,
        completedReviews: completedReviewsRes.list,
        pendingReviews: pendingRes.list,
        reviewType: pendingRes.list.length > 0 ? 'pending' : 'complete'
      }, () => {
        if(this.props.searchParams.get('getreviews')){
          setTimeout(() => {
            this.onClickGetReviews()
          }, 200);
        }
      })
    })
  };

  tryShowOnboarding = () => {
    if(this.props.searchParams.get('getreviews')) return;
    this.setState({ onboardingTooltipVisible: true });
  };

  onClickCancelReviews = () => {
    Modal.confirm({
      title: "Cancel Reviews?",
      content: (
        <div>
          This will cancel all reviews that are in progress, and remove them from your reviewer's reading list. Your locked points will be returned
          to you. Are you sure?
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: "Cancel Reviews",
      cancelText: "Nevermind",
      onOk: this.confirmCancelReviews,
      maskClosable: true,
    });
  };

  closeGetReviewsModal = () => {
    this.setState({ isGetReviewsModalVisible: false });
  };

  onClickGetReviews = () => {
    this.setState({
      isGetReviewsModalVisible: true,
      onboardingTooltipVisible: false,
    });
  };

  confirmCancelReviews = () => {
    this.setState({cancelReviewsLoading: true});
    api(`/cancelPendingReviews`, {body: {
      bookId: this.state.book.id
    }}).then(res => {
      if(res.status === 'success'){
        this.setState({cancelReviewsLoading: false});
        this.setState(initialState, () => this.load());
        setTimeout(() => {
          if(res.notCancelledCount) alert(`${res.cancelledCount} reviews were cancelled. ${res.notCancelledCount} reviews were unable to be cancelled, since the readers have already submitted them.`)
        }, 200);
        if(!res.notCancelledCount) message.success(`Cancelled ${res.cancelledCount} Pending Reviews`);
        getPointsTransactions().then(res => {
          setPointsTransactions(res)
        });
        getMyUser().then(res => {
          updateLoggedInUser(res)
        });


      }else{
        this.setState({cancelReviewsLoading: false});
        alert(res.message)
      }
    })
  };

  onChangeReviewType = (e) => {
    this.setState({ reviewType: e.target.value });
  };

  onChangeMessage = (e) => {
    if (this.state.savingMessage) return;
    this.setState({
      book: {
        ...this.state.book,
        messageToReaders: e.target.value,
      },
    });
    if (this.messageTimeout) clearTimeout(this.messageTimeout);
    this.messageTimeout = setTimeout(() => this.saveMessage(), 1000);
  };

  saveMessage = () => {
    if (this.messageTimeout) clearTimeout(this.messageTimeout);
    this.setState({ savingMessage: true });

    editBookMessage(this.state.book).then((res) => {
      this.setState({ savingMessage: false });
      setTimeout(() => {
        this.setState({ messageSaved: true });
      }, 300);
      setTimeout(() => {
        this.setState({ messageSaved: false });
      }, 3000);
    });
  };

  doIHaveEnoughPoints = () => {
    const myPoints = this.props.loggedInUser.points;
    const bookPoints = this.state.book.points;
    if (!myPoints) return false;
    if (myPoints >= bookPoints) {
      return true;
    } else {
      return false;
    }
  };

  onGetReviewsSuccess = () => {
    // reload 
    this.setState(initialState, () => this.load());
    getPointsTransactions().then(res => {
      setPointsTransactions(res)
    });
    getMyUser().then(res => {
      updateLoggedInUser(res)
    })
    setTimeout(() => {
      message.success("Reviews Requested!");
    }, 200);
  }

  render() {
    const { book = {}, savingMessage, messageSaved, loading, pendingReviews, completedReviews } = this.state;
    const numberOfPendingReviews = pendingReviews.length;

    let notEnoughPoints = this.doIHaveEnoughPoints() ? false : true;


    return (
      <PageTemplate scroll loading={loading} maxWidth={1100}>
        {notEnoughPoints ? null : <GetReviewsModal visible={this.state.isGetReviewsModalVisible} onCancel={this.closeGetReviewsModal} book={book} onSubmitSuccess={this.onGetReviewsSuccess} />}
        <div style={styles.body}>
          <PageTitle title="View My Upload" />
          {book.rejected ? <Alert showIcon style={{maxWidth: 500}} type='error' message={`This book has been rejected, and is not approved for the platform. ${book.approvalReason ? `Reason: ${book.approvalReason}` : ''}`} /> : null}
          <div style={{ height: 30 }} />
          <div style={styles.top}>
            <div style={styles.topLeft}>
              <BookBox book={book} noEllipsis />
            </div>
            <div style={styles.topRight}>
              <div style={styles.label}>Message to Readers:</div>
              <Input.TextArea
                disabled={savingMessage}
                rows={4}
                placeholder="We hope you enjoy this book! ..."
                onChange={this.onChangeMessage}
                value={book.messageToReaders}
              />
              <div style={styles.saveMessageButtonRow}>
                <Button style={styles.saveMessageButton} size="small" disabled={savingMessage} loading={savingMessage} onClick={this.saveMessage}>
                  {messageSaved ? "Saved!" : "Save Message"}
                </Button>
              </div>
            </div>
          </div>
          <div style={styles.hr} />
          <div style={styles.reviewsTop}>
            <div style={styles.reviewsTopLeft}>
              <div style={styles.title}>{this.state.reviewType === 'pending' ? `Pending Reviews (${pendingReviews.length})` : `Completed Reviews (${completedReviews.length})`}</div>
              <Radio.Group
                options={[
                  { label: "Completed", value: "complete" },
                  { label: "Pending", value: "pending" },
                ]}
                onChange={this.onChangeReviewType}
                value={this.state.reviewType}
                optionType="button"
                // buttonStyle="solid"
              />
            </div>
            <div style={styles.reviewsTopRight}>
              <div style={styles.reviewStatusRow}>
                <span style={styles.reviewStatusLabel}>Status:</span>
                <span style={styles.reviewStatusValue}>{numberOfPendingReviews} review{numberOfPendingReviews !== 1 ? 's' : ''} pending</span>
              </div>
              <div style={{ height: 8 }} />
              {numberOfPendingReviews ? (
                <Button danger size="small" type='text' icon={<CloseCircleOutlined />} onClick={this.onClickCancelReviews} loading={this.state.cancelReviewsLoading}>
                  Cancel Pending Reviews
                </Button>
              ) : notEnoughPoints ? (
                <Tooltip placement="bottomLeft" title={book.needsReview ? "You book needs to be approved by BookGiveaway before you can start getting reviews." : "You need to earn more points before you can get reviews for your book"}>
                  <Button type="primary" disabled={true} visible={this.state.onboardingTooltipVisible}>
                    Get Reviews!
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  placement="left"
                  visible={this.state.onboardingTooltipVisible}
                  title={"Click here to get started receiving reviews for your book"}
                  color={colors.secondary}
                >
                  <Button size='large' type="primary" onClick={this.onClickGetReviews}>
                    Get Reviews!
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div style={styles.reviewsContent}>
            {this.state.reviewType === "complete" ? (
              <>
                {completedReviews.map(r => {
                  return <ReviewBox review={r} />
                })}
              </>
            ) : (
              <>
                <Table columns={columns} style={styles.table} dataSource={pendingReviews} pagination={false} />
              </>
            )}
          </div>
          <div style={{ height: 10 }} />
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  topRight: {
    width: 350,
  },
  topLeft: {
    paddingRight: 50,
  },
  label: {
    color: colors.text,
    marginBottom: 4,
  },
  saveMessageButton: {
    marginTop: 6,
    width: 145,
  },
  saveMessageButtonRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  hr: {
    height: 1,
    backgroundColor: colors.lightBG,
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    fontWeight: "700",
    fontSize: 28,
    marginBottom: 5,
  },
  reviewsTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reviewsTopRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  reviewStatusRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  reviewStatusLabel: {
    fontWeight: "700",
  },
  reviewStatusValue: {
    marginLeft: 7,
  },
  table: {
    marginTop: 20,
    width: 600,
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(ViewMyUploadPage));
