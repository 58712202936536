export default {
  main: '#407AFD',
  secondary: '#A0BDFF',
  text: '#191919',
  border: '#e0e0e0',
  lightText: '#c4c4c4',
  lightBG: '#e6eaf0',
  // lightBG: '#F5F5F5',
  labelText: '#b0b0b0',

  selected: '#407AFD',
  secondary: '#A0BDFF',
  highlight: '#e6eaf0',
  frozen: '#36C0EC',
  red: "#FF3B30",
  green: '#30D158',
  warning: '#FAAD14'
}