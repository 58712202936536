import * as types from '../redux/types';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import store from "../redux/store";
import { addActivity } from "./activity";
import {db} from './index';

export function getPointsTransactions(){
  return new Promise(async resolve => {
    let state = store.getState();
    const q = query(collection(db, "pointsTransactions"), where("spenderId", "==", state.loggedInUser.id), where('isActive', '==', true));
    const querySnapshot = await getDocs(q);
    let transactions = [];
    let lockedPoints = 0;
    
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      transactions.push({
        ...doc.data(),
        id: doc.id
      })
      lockedPoints += doc.data().points
    });
    resolve({list: transactions, lockedPoints})
  })
}