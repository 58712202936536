import React from 'react';
import pointsIcon from '../assets/img/points.png';

const Points = ({amount, extraText=''}) => {
  return(
    <div style={styles.pointsWrapper}>
    <img src={pointsIcon} style={styles.pointsIcon} />
    <div style={styles.pointsText}>{amount}{extraText}</div>
  </div>
  );
}

const styles = {
  pointsIcon: {
    height: 22,
    marginRight: 3
  },
  pointsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
    cursor: 'default'

  },
  pointsText: {
    fontWeight: 600
  },
};

export default Points;