import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, message, Select } from "antd";
import React, { Component } from "react";
import EditBookClass from "../../classes/EditBookClass";
import Input from "../../components/inputs/Input";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import api from "../../firebase/api";
import { addBook } from "../../firebase/books";
import { addFile } from "../../firebase/files";
import { withRouter } from "../../helpers/withRouter";
import BookTypeRow from "./BookTypeRow";
import FileBox from "./FileBox";

const { Option } = Select;

let genres = [
  "Nonfiction",
  "Fiction",
  "Science",
  "Science Fiction",
  "Fantasy",
  "Romance",
  "Adventure",
  "Dystopian",
  "Mystery",
  "Thriller",
  "Horror",
  "Paranormal",
  "Childrens",
  "Self Help",
  "Motivational",
  "Education",
  "Business",
  "Health",
  "Cooking",
  "Travel",
  "Young Adult",
].sort((a, b) => (a > b ? 1 : -1));

class UploadNewBookPage extends EditBookClass {
  constructor(props) {
    super(props);
    this.state = {
      book: {},
      isUploadingCover: false,
      errorState: {}
    };
  }



  isValidated = () => {
    const { book } = this.state;
    let errorState = {};
    if(!book.title) errorState.title = true;
    if(!book.author) errorState.author = true;
    if(!book.amazonUrl) errorState.amazonUrl = true;
    if(!book.genre) errorState.genre = true;
    if(!book.coverUrl) errorState.coverUrl = true;
    if(!book.pdfUrl && book.bookTypeStandard) errorState.pdfUrl = true;

    this.setState({errorState: errorState})
    console.log('erorstate', errorState)
    if(Object.keys(errorState).length > 0){
      message.error("Please fill out all the fields")
      return false
    }else{
      return true
    }
  }

  submit = async () => {
    this.setState({hasSubmittedBefore: true})
    if(!this.isValidated()) return;
    this.setState({errorState: {}})
    this.setState({ submitting: true });
    await addBook({
      ...this.state.book,
    });
    this.setState({ submitting: false, book: {} });
    message.success("Book Submitted!");
    this.props.navigate(`/myuploads`);
  };

  addImage = (file) => {
    this.setState({ isUploadingCover: true });
    addFile(file, "image").then((res) => {
      this.setState({
        isUploadingCover: false,
      });
      this.onChangeBook("coverUrl", res);
    });
  };

  render() {
    const { submitting, book, isUploadingPdf, errorState } = this.state;
    const { coverUrl, pdfUrl } = book;


    return (
      <PageTemplate maxWidth={700} scroll>
        <PageTitle title="Upload New Book" />
        <div style={{ height: 20 }} />

        <Input inline label="Title" onChange={(e) => this.onChangeBook("title", e.target.value)} value={book.title} error={errorState.title} />
        <Input inline label="Author" onChange={(e) => this.onChangeBook("author", e.target.value)} value={book.author} error={errorState.author}/>
        <Input inline label="Amazon URL" onChange={(e) => this.onChangeBook("amazonUrl", e.target.value)} value={book.amazonUrl} error={errorState.amazonUrl}/>
        <Input inline label="Genre">
          <Select value={book.genre} onChange={(x) => this.onChangeBook("genre", x)} style={{ width: 200 }} status={errorState.genre ? 'error' : undefined}>
            {genres.map((x) => (
              <Option value={x}>{x}</Option>
            ))}
            <Option value="Other">Other</Option>
          </Select>
        </Input>
        <Input inline label="Explicit content?">
          <Checkbox checked={book.explicit} onChange={(e) => this.onChangeBook("explicit", e.target.checked)}></Checkbox>
        </Input>

        <Input inline label="Cover Photo">
          {coverUrl ? <img src={this.state.book.coverUrl} style={styles.cover} /> : null}
          <Button error={errorState.title} size='small' type={book.coverUrl ? undefined : 'primary'} onClick={() => this.imageInput.click()} icon={<UploadOutlined />} loading={this.state.isUploadingCover}>
            {coverUrl ? "Change Photo" : "Upload Cover Photo"}
          </Button>
        </Input>
        <input
          type="file"
          accept={"image"}
          ref={(x) => (this.imageInput = x)}
          style={styles.hiddenInput}
          onChange={(e) => this.addImage(e.target.files[0])}
        ></input>

        <div style={styles.title}>Book Types</div>
        <BookTypeRow
          title="Standard"
          desc="Upload a .pdf of your book for users to read"
          onChange={this.onChangeBookTypeStandard}
          value={book.bookTypeStandard}
          extra={
            <>
              {pdfUrl && !isUploadingPdf ? (
                <FileBox url={pdfUrl} onClickChange={() => this.pdfInput.click()} />
              ) : (
                <Button
                  size="small"
                  onClick={() => this.pdfInput.click()}
                  icon={<UploadOutlined />}
                  style={{ marginTop: 3 }}
                  loading={isUploadingPdf}
                  type={!pdfUrl && book.bookTypeStandard ? 'primary' : undefined}
                  disabled={!book.bookTypeStandard}
                >
                  Upload PDF
                </Button>
              )}
              <input
                type="file"
                accept={"application/pdf"}
                ref={(x) => (this.pdfInput = x)}
                style={styles.hiddenInput}
                onChange={(e) => this.addPdf(e.target.files[0])}
              ></input>
            </>
          }
        />
        <BookTypeRow title="Kindle Unlimited" onChange={this.onChangeBookTypeKindle} value={book.bookTypeKindle} />
        <BookTypeRow
          title="Verified Purchase"
          desc="Readers must purchase your book on Amazon, and read it on kindle"
          onChange={x => this.onChangeVerifiedPurchase(x)}
          value={book.bookTypeVerifiedPurchase}
        />
        <div style={styles.submitButtonRow}>
          <Button size="large" type="primary" onClick={this.submit} loading={submitting} disabled={submitting} style={{width: 180}}>
            Submit Book
          </Button>
          {this.getErrorStateAlert()}
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  title: {
    fontWeight: "700",
    fontSize: 20,
    marginTop: 30,
    marginBottom: 20,
  },
  submitButtonRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 50,

  },
  hiddenInput: {
    visibility: "hidden",
    display: "none",
  },
  cover: {
    marginRight: 20,
    height: 100,
    maxWidth: 100,
    // borderRadius: 3,
  },
};

export default withRouter(UploadNewBookPage);
