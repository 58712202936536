import { v4 as uuid } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";

export const addFile = function (file, type='image') {
  return new Promise(async (resolve, reject) => { 
    let processedFile;
    let prefix;

    if(type === 'image'){
      prefix = 'user_uploads';
      const compressOptions = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 700,
        useWebWorker: true,
      };
      processedFile = await imageCompression(file, compressOptions);
    }

    if(type === 'pdf'){
      processedFile = file;
      prefix = 'user_uploads';
    }

    const randomName = uuid();
    let filePath = `${prefix}/${randomName}`;
    const storage = getStorage();
    const storageRef = ref(storage, filePath);
    
    uploadBytes(storageRef, processedFile).then((snapshot) => {
      let fileUrl = `https://firebasestorage.googleapis.com/v0/b/bookreview-cf166.appspot.com/o/${encodeURIComponent(snapshot.metadata.fullPath)}?alt=media`;
      resolve(fileUrl);
    });
  });
};

