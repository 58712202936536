import { Table } from "antd";
import React, { Component } from "react";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import PageTitle from "../../../components/PageTitle";
import { adminGetReviews } from "../../../firebase/reviews";
import getAgo from "../../../helpers/getAgo";
import { withRouter } from "../../../helpers/withRouter";


const cols = [
  {
    title: 'Book Title',
    dataIndex: 'book',
    key: 'book',
    render: data => (
      <div>{data.title}</div>
    ),
  },
  {
    title: 'Reviewer',
    dataIndex: 'user',
    key: 'user',
    render: data => (
      <div>{data.email}</div>
    ),
  },
  {
    title: 'Submitted At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: data => (
      <div>{getAgo(data)}</div>
    ),
  },
];

class AdminNewReviewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reviews: []
    };
  }

  componentDidMount(){
    this.load();
    this.loadInterval = setInterval(() => this.load(), 1000*30);
  }

  componentWillUnmount(){
    if(this.loadInterval) clearInterval(this.loadInterval);
  }

  onClickRow = (record) => {
    this.props.navigate(`/admin/newreviews/${record.id}`);
  };

  load = () => {
    adminGetReviews().then(res => {
      this.setState({reviews: res.list, loading: false})
    })
  }

  render() {
    let tableData = this.state.reviews;

    return (
      <AdminPageTemplate>
        <PageTitle title={`New Reviews (${tableData.length})`} />
        <div style={styles.pageBody}>
          <Table
            columns={cols}
            loading={this.state.loading}
            dataSource={tableData}
            pagination={false}
            scroll={{
              y: "calc(100vh - 200px)",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.onClickRow(record);
                },
              };
            }}
            rowClassName="antd-table-row-clickable"
          />
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  pageBody: {
    paddingTop: 10,
  },
};

export default withRouter(AdminNewReviewsPage);
