import store from '../store';
import * as types from '../types';

export function setMyUploads(data){
  store.dispatch({
    type: types.SET_MY_UPLOADS,
    payload: data
  })
}

export function setLibrary(data){
  store.dispatch({
    type: types.SET_LIBRARY,
    payload: data
  })
}

export function setMyAssignments(data){
  store.dispatch({
    type: types.SET_MY_ASSIGNMENTS,
    payload: data
  })
}



