import React, { useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import colors from '../../colors';

const TitleRow = ({title, onClick, onClickAdd, selected}) => {
  const [addHover, setAddHover] = useState(false);

  const onMouseEnterAdd = () => {
    setAddHover(true)
  };
  const onMouseLeaveAdd = () => {
    setAddHover(false)
  };

  return(
    <div style={{...styles.wrapper, ...(selected ? styles.selected : {})}} className={selected ? '' : 'pianoHighlight'} onClick={onClick}>
      <div style={{...styles.title, ...(selected ? styles.selectedTitle : {})}}>{title}</div>
      <div style={styles.addWrapper} onClick={onClickAdd} onMouseEnter={onMouseEnterAdd} onMouseLeave={onMouseLeaveAdd}>
        <MdAddCircle size={18} color={selected ? '#fff' : colors.text} style={{opacity: addHover ? 1 : .6 }}/>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    marginBottom: 8,
    borderRadius: 5,
    cursor: 'pointer'
  },
  selected: {
    backgroundColor: colors.selected
  },
  selectedTitle: {
    color: '#fff'
  },

  addIcon: {
    height: 18,
    maxWidth: 18
  },
  title: {
    marginLeft: 8,
    fontWeight: 700
  },
  addWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
};

export default TitleRow;