import { Table } from "antd";
import React, { Component } from "react";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import PageTitle from "../../../components/PageTitle";
import { adminGetNewBooks } from "../../../firebase/books";
import getAgo from "../../../helpers/getAgo";
import { withRouter } from "../../../helpers/withRouter";

const cols = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (data) => <div>{data}</div>,
  },
  {
    title: "Submitted",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data) => <div>{getAgo(data)}</div>,
  },
];

class AdminNewBooksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
    };
  }

  componentDidMount() {
    this.load();
    this.loadInterval = setInterval(() => this.load(), 1000 * 30);
  }

  componentWillUnmount() {
    if (this.loadInterval) clearInterval(this.loadInterval);
  }

  load = () => {
    adminGetNewBooks().then((res) => {
      this.setState({
        loading: false,
        books: res.list,
      });
    });
  };

  onClickRow = (record) => {
    this.props.navigate(`/admin/newbooks/${record.id}`);
  };

  render() {
    let tableData = [...this.state.books];

    return (
      <AdminPageTemplate>
        <PageTitle title={`New Books (${tableData.length})`} />
        <div style={styles.pageBody}>
          <Table
            columns={cols}
            dataSource={tableData}
            pagination={false}
            scroll={{
              y: "calc(100vh - 200px)",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.onClickRow(record);
                },
              };
            }}
            rowClassName="antd-table-row-clickable"
          />
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  table: {
    height: "100%",
  },
  pageBody: {
    paddingTop: 10,
  },
  test: {},
};

export default withRouter(AdminNewBooksPage);
