import { AmazonOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, DatePicker, Input as AntInput, message, Rate, Tag } from "antd";
import React, { Component } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { MdPersonOutline } from "react-icons/md";
import colors from "../../../colors";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import BookBox from "../../../components/BookBox/BookBox";
import Input from "../../../components/inputs/Input";
import PageTitle from "../../../components/PageTitle";
import api from "../../../firebase/api";
import { getBook } from "../../../firebase/books";
import { adminGetReview } from "../../../firebase/reviews";
import { adminGetUser } from "../../../firebase/users";
import { withRouter } from "../../../helpers/withRouter";
import moment from "moment";

class AdminViewNewReviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: {},
      user: {},
      book: {},
      loading: true,
    };
  }

  onChangeReview = (key, val) => {
    this.setState({
      review: {
        ...this.state.review,
        [key]: val,
      },
    });
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    adminGetReview(this.props.params.id).then(async (reviewRes) => {
      let promises = [getBook(reviewRes.bookId), adminGetUser(reviewRes.reviewerId)];
      if (!reviewRes.successfullyScrapedReview && reviewRes.numberOfScrapeFails < 3) {
        promises.push(api(`/scrapeAndSetAmazonReview`, { body: { reviewId: this.props.params.id } }));
      }
      Promise.all(promises).then((promiseRes) => {
        let newState = {
          review: { ...reviewRes },
          loading: false,
          book: promiseRes[0],
          user: promiseRes[1],
        };
        let scrapedReview = reviewRes.scrapedReview;
        if (promiseRes.length > 2) scrapedReview = promiseRes[2].scrapedReview;
        if (scrapedReview) {
          newState.review.scrapedReview = scrapedReview;
          newState.review.reviewText = scrapedReview.review;
          newState.review.hasVerifiedPurchase = scrapedReview.verified_purchase;
          newState.review.reviewUrl = `https://www.amazon.com/gp/customer-reviews/${scrapedReview.id}`;
          newState.review.rating = scrapedReview.rating;
          newState.review.reviewDate = scrapedReview.date.unix*1000;
          newState.review.approved = true;
        }
        this.setState(newState);
      });
    });
  };

  onClickViewUser = () => {
    this.props.navigate(`/admin/users/${this.state.review.reviewerId}`);
  };

  onChangeReviewDate = m => {
    this.onChangeReview('reviewDate', m.utc().valueOf())
  }

  submit = () => {
    this.setState({ submitting: true });
    api(`/adminSubmitReviewDecision`, {body: {
      review: this.state.review
    }}).then(res => {
      if(res.status === 'success'){
        if(this.state.review.approved){
          message.success("Review Submitted!");
        }else{
          message.success("Review Rejected");
        }
        this.props.navigate(`/admin/newreviews`)
      }else{
        message.error("Error submitting review.");
        this.props.navigate(`/admin/newreviews`)
      }
    })
  };

  onClickViewReview = () => {
    let url = this.state.review.reviewUrl;
    window.open(url, "_blank");
  };

  onChangeReviewRating = data => {
    this.onChangeReview('rating', data)
  }

  render() {
    const { review, submitting, user, book } = this.state;
    let scrapedReview = review.scrapedReview || {};
    // scrapedReview = {}

    let showUnverifiedAlert = book.bookTypeVerifiedPurchase && !review.hasVerifiedPurchase;

    return (
      <AdminPageTemplate scroll maxWidth={900} loading={this.state.loading}>
        <div style={styles.top}>
          <PageTitle title="View Review" />
          <div style={{ height: 20 }} />
          <Input label="For Book" inline>
            <BookBox showLink book={this.state.book} key={'bb'+this.state.book.id} />
          </Input>
          <div style={{ height: 20 }} />
          <Input inline label="Verified Purchase Required?">
            <div>{book.bookTypeVerifiedPurchase ? "Yes" : "No"}</div>
          </Input>
          <Input label={"Reviewer Amazon Name"} inline>
            <div>{review.amazonUsername}</div>
          </Input>
          <Input inline label="Reviewer">
            <Button size="small" icon={<UserOutlined />} onClick={this.onClickViewUser}>
              {user.email}
            </Button>
          </Input>
          {/* {scrapedReview.name ? 
            <Input inline label="Review">
              <Button size="small" icon={<AmazonOutlined />} onClick={this.onClickViewReview}>
                View Review on Amazon
              </Button>
            </Input>
          :null} */}
        </div>
        <div style={styles.bottom}>
          <div style={styles.hr} />
          <div style={styles.title}>
            Review Info
            {scrapedReview.id ? (
              <Tag style={{ marginLeft: 15 }} color="green">
                Found Review with Automated System
              </Tag>
            ) : null}
          </div>
          <div style={{ height: 10 }} />

          {showUnverifiedAlert ? (
            <Alert
              style={{ marginBottom: 20 }}
              type="warning"
              showIcon
              message={`The book owner requested this review to be verified, but it is NOT verified.`}
            />
          ) : null}

          {scrapedReview.id ? null : (
            <Alert style={{ marginBottom: 20 }} type="warning" showIcon message={`Could not locate review using automated system.`} />
          )}

          {review.messageToAdmin ? 
            <Input inline label={"Message From User"}>
              <div style={{fontWeight: '600'}}>{review.messageToAdmin || ''}</div>
            </Input>
          :null}
          <Input inline label={"Review Text"}>
            <AntInput.TextArea rows={4} value={review.reviewText} onChange={(e) => this.onChangeReview("reviewText", e.target.value)} />
          </Input>
          <Input inline label={"Link"}>
            <AntInput value={review.reviewUrl} onChange={(e) => this.onChangeReview("reviewUrl", e.target.value)} />
            <a href={review.reviewUrl} style={{ marginLeft: 8 }} target="_blank">
              view
            </a>
          </Input>
          <Input inline label={"Review Date"}>
            <DatePicker onChange={this.onChangeReviewDate} value={moment(this.state.review.reviewDate)} />
          </Input>
          <Input inline label={"Review Username"}>
            <AntInput value={review.amazonUsername} onChange={(e) => this.onChangeReview("amazonUsername", e.target.value)} />
          </Input>
          <Input inline label={"Rating"}>
            <Rate value={this.state.review.rating} onChange={this.onChangeReviewRating} />
          </Input>
          <Input label="Review Has Verified Purchase?" inline>
            <Checkbox checked={review.hasVerifiedPurchase} onChange={(e) => this.onChangeReview("hasVerifiedPurchase", e.target.checked)} />
            <div style={{ width: 10 }} />
            {review.hasVerifiedPurchase ? <Tag color="green">Yes</Tag> : null}
          </Input>

          <div style={styles.hr} />
          <div style={styles.title}>Admin Actions</div>
          <div style={{ height: 10 }} />

          <Input label="Approved?" inline>
            <Checkbox checked={review.approved} onChange={(e) => this.onChangeReview("approved", e.target.checked)} />
          </Input>
          <Input
            inline
            label={"Message to reviewer"}
            value={review.messageToReviewer}
            onChange={(e) => this.onChangeReview("messageToReviewer", e.target.value)}
          />

          <Button
            style={{ marginTop: 20 }}
            type={review.approved ? "primary" : "danger"}
            onClick={this.submit}
            loading={submitting}
            disabled={submitting}
          >
            {review.approved ? "Approve Review" : "Reject Review"}
          </Button>
          {/* <div style={{height: 50}} /> */}
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  hr: {
    height: 1,
    width: "100%",
    backgroundColor: colors.lightBG,
    marginTop: 25,
    marginBottom: 25,
  },
  title: {
    fontWeight: "700",
    fontSize: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bottom: {
    // width: '100%',
  },
};

export default withRouter(AdminViewNewReviewPage);
