import * as types from '../redux/types';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc  } from "firebase/firestore";
import store from "../redux/store";
import { addActivity } from "./activity";
import {db} from './index';

export function getLibrary(opts){
  return new Promise(async resolve => {
    let state = store.getState();
    const r_ref = collection(db, "assignmentRequests");
    const a_ref = collection(db, "assignments");
    // const q = query(assignmentRequestsRef, where("approved", "==", true));
    const r_query = query(r_ref, where("isActive", "==", true), where("assigned", '==', false));
    const a_query = query(a_ref, where("cancelled", "==", false), where('userId', '==', state.loggedInUser.id));

    const r_snapshot = await getDocs(r_query);
    const a_snapshot = await getDocs(a_query);
    let rs = [];
    let alreadyAddedBookIds = [];
    let as = [];
    a_snapshot.forEach(a => {
      as.push(a.data())
    })

    r_snapshot.forEach((r) => {
      let hasAlreadyRead = false;
      as.forEach(a => {
        if(a.bookId === r.data().bookId) hasAlreadyRead = true;
      })
      if(
        !r.data().assignedUserId &&
        alreadyAddedBookIds.indexOf(r.data().bookId) < 0 &&
        hasAlreadyRead === false &&
        r.data().authorId !== state.loggedInUser.id
      ){
        rs.push(new Promise(async resolve2 => {
          const docRef = doc(db, "books", r.data().bookId);
          const docSnap = await getDoc(docRef);
          return resolve2({
            ...r.data(),
            id: r.id,
            book: docSnap.data()
          })

        }))
        alreadyAddedBookIds.push(r.data().bookId)
      }
    });
    Promise.all(rs).then(list => {
      list = list.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
      resolve({list});
    })
  })
}

export function getAssignmentRequest(id){
  return new Promise(async resolve => {
    const docRef = doc(db, "assignmentRequests", id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      resolve(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })
}
