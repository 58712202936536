import React, { Component } from "react";

class CustomFadeIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true });
    }, 100);
  }

  render() {
    const { opacityOnly, maxWidth } = this.props;

    const styles = {
      container: {
        maxWidth: maxWidth || undefined,
        flex: 1,
        width: '100%',
        display: "flex",
        // alignItems: 'center',
        justifyContent: 'center',
        minHeight: "min-content",
        overflow: "hidden",
        position: "relative",
      },
    };

    if (opacityOnly) {
      return (
        <div className={this.state.show ? "fade-in-show-opacity" : "fade-in-hide-opacity"} style={styles.container}>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className={this.state.show ? "fade-in-show" : "fade-in-hide"} style={styles.container}>
          {this.props.children}
        </div>
      );
    }
  }
}

export default CustomFadeIn;
