import React from 'react';

const PageTitle = ({title}) => {
  return(
    <div style={styles.wrapper}>{title}</div>
  );
}

const styles = {
  wrapper: {
    fontWeight: '700',
    fontSize: 28,
    // textAlign: 'center'
  }
};

export default PageTitle;