import React from "react";
// import bookIcon from '../../assets/img/bookIcon.png';
import { GiWhiteBook } from "react-icons/gi";
import { useParams } from "react-router-dom";
import colors from "../../colors";

const BookRow = ({ book = {}, id, onClick }) => {
  let selected = false;
  const params = useParams();
  if (!params) params = {};
  if (params.id === book.id || (params.id === id && params.id !== undefined)) {
    selected = true;
  }

  return (
    <div style={{ ...styles.wrapper, ...(selected ? styles.selected : {}) }} className={selected ? "" : "pianoHighlight"} onClick={onClick}>
      <div style={styles.bookWrapper}>
        <GiWhiteBook size={18} color={selected ? "#fff" : colors.text} />
      </div>
      <div style={{ ...styles.title, ...(selected ? styles.selectedTitle : {}) }}>{book.title}</div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
    padding: 6,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 5,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: colors.selected,
  },
  selectedTitle: {
    color: "#fff",
  },
  title: {
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bookWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default BookRow;
