import React, { useState, useEffect } from "react";
import pointsIcon from "../../assets/img/points.png";
import dollarSign from "../../assets/img/dollar.png";
import colors from "../../colors";
import { InfoCircleOutlined, InfoOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { getPendingReviews } from "../../firebase/reviews";
import PendingApproval from "../../components/PendingApproval";

const MyUploadBox = ({ book = {}, onClick, canShowGetReviews }) => {
  const [hover, setHover] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [reviews, setReviews] = useState({
    loading: true,
    list: []
  });

  useEffect(() => {
    getPendingReviews(book.id).then((res) => {
      setReviews({loading: false, list: res.list});
    });
  }, []);

  let reviewsPending = reviews.list.length;

  let onGetReviews = e => {
    e.stopPropagation();
    onClick({getReviews: true});
  }

  if(reviews.loading) canShowGetReviews = false


  const styles = {
    wrapper: {
      width: 600,
      // backgroundColor: 'red',
      maxHeight: 300,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      // marginBottom: 25,
      cursor: "pointer",
      margin: 15,
      paddingLeft: 15,
      transition: ".2s",
      backgroundColor: "#f7f7f7",
      borderRadius: 8,
      padding: 15,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "transparent",
      marginLeft: hover ? 10 : 0,
      marginRight: hover ? 0 : 10,

      // position: 'relative',
    },
    title: {
      fontWeight: 600,
      marginBottom: 15,
      fontSize: 20,
    },
    imageWrapper: {
      marginRight: 15,
      marginRight: 15,
      height: imgLoaded ? undefined : 270,
      width: imgLoaded ? undefined : 200,
      // borderRadius: 8,
      backgroundColor: imgLoaded ? undefined : "#d1d1d1",
    },
    image: {
      // height: 200,
      maxWidth: 200,
      maxHeight: 270,
      // marginTop: 15,
      // marginBottom: 15,
      // borderRadius: 8,
    },
    right: {
      height: "100%",
      // backgroundColor: 'pink',
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      // backgroundColor: 'red',
    },
    dot: {
      height: 12,
      width: 12,
      borderRadius: 6,
      backgroundColor: reviewsPending ? colors.green : "transparent",
      marginRight: 6,
    },
    reviewsPendingText: {
      fontStyle: "italic",
      color: reviewsPending ? colors.text : colors.lightText,
    },
    reviewsPendingRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.wrapper} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClick}>
      <div style={styles.imageWrapper}>
        <img src={book.coverUrl} style={styles.image} onLoad={() => setImgLoaded(true)} />
      </div>
      <div style={styles.right}>
        <div style={styles.title} className="bookDesc">
          {book.title}
        </div>
        {reviewsPending !== undefined ? (
          <>
            <div style={styles.reviewsPendingRow}>
              {reviewsPending ? <div style={styles.dot}></div> : null}
              <div style={styles.reviewsPendingText}>
                {reviewsPending} Review{reviewsPending > 1 || reviewsPending === 0 ? "s" : ""} Pending
              </div>
            </div>
            {reviewsPending === 0 && canShowGetReviews ? (
              <Button size='large' onClick={onGetReviews} style={{ marginTop: 15 }} type="primary">
                Get Reviews!
              </Button>
            ) : null}
          </>
        ) : null}
        {book.needsReview ? <PendingApproval /> : null}
      </div>
    </div>
  );
};

export default MyUploadBox;
