import React, {Component} from 'react';
import BookBox from '../../components/BookBox';
import PageTemplate from '../../components/PageTemplate';
import PageTitle from '../../components/PageTitle';
import { getLibrary } from '../../firebase/assignmentRequests';
import {withRouter} from '../../helpers/withRouter';
import { setLibrary } from '../../redux/functions';
import { connect } from 'react-redux';
import IllustrationMessage from '../MyAssignmentsPage/IllustrationMessage';
import libraryIllustration from '../../assets/img/illustrations/library1.svg';

class LibraryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      books: []
    };
  }

  componentDidMount(){
    this.loadBooks()
  }

  loadBooks = () => {
    getLibrary().then(res => {
      setLibrary(res);
    })
  }

  onClickReviewRequest = r => {
    this.props.navigate(`/viewbook/${r.id}`)
  }

  render() {
    let isEmpty = this.props.library.list.length === 0 && !this.props.library.loading;
    return(
      <PageTemplate maxWidth={5000} loading={this.props.library.loading}>
      {isEmpty ? null : <PageTitle title='Library - Choose an Assignment' />}
      <div style={styles.bookRow}>
        {isEmpty ? 
          <IllustrationMessage illustration={libraryIllustration} title='The Library is Empty' desc="Please check back later once more books have been uploaded." />
        :null}
        {this.props.library.list.map(r => {
          return(
            <BookBox key={'bblib'+r.id} isListItem={true} book={r.book} onClick={() => this.onClickReviewRequest(r)} />
          )
        })}
      </div>
    </PageTemplate>
    );
  }
}

const styles = {
  bookRow: {
    display: 'flex',
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflowY: 'auto'
  }
};

const mapStateToProps = state => ({
  library: state.library
});
const mapActionsToProps = {
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(LibraryPage));