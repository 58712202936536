export default function getAgo(date){
  if(!date) return '? ago'
  if(typeof date === 'number'){
    date = new Date(date)
  }
  if(typeof date === 'string'){
    date = parseInt(date)
    date = new Date(date)
  }

  let now = new Date().getTime();
  let then = date.getTime();

  let diff = now - then;
  let minDiff = diff/(1000*60);
  if(minDiff < 1) minDiff = 1;
  let hourDiff = minDiff/60;
  let dayDiff = hourDiff/24;

  let diffStr = `${Math.floor(minDiff)}m`;
  if(hourDiff > 24) diffStr = `${Math.floor(dayDiff)}d`
  if(hourDiff <= 24) diffStr = `${Math.floor(hourDiff)}h`;
  if(hourDiff <1) diffStr = `${Math.floor(minDiff)}m`;

  return(`${diffStr} ago`);

}