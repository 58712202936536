import React, { useState } from "react";
import pointsIcon from "../../assets/img/points.png";
import dollarSign from "../../assets/img/dollar.png";
import Points from "../Points";
import PendingApproval from "../PendingApproval";
import ExplicitTag from "../ExplicitTag";

const BookBox = ({ book = {}, isListItem, onClick, showLink, noEllipsis }) => {
  const [hover, setHover] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  let { points, price, genre, numberOfWords, title } = book;

  const styles = {
    wrapper: {
      width: 600,
      // backgroundColor: 'red',
      maxHeight: 300,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      // marginBottom: 25,
      cursor: isListItem ? "pointer" : undefined,
      margin: isListItem ? 15 : 0,
      paddingLeft: isListItem ? 15 : 0,
      transition: ".2s",
      backgroundColor: isListItem ? (hover ? "#f7f7f7" : "#f7f7f7") : "transparent",
      borderRadius: 8,
      padding: isListItem ? 15 : 0,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "transparent",

      // position: 'relative',
    },
    title: {
      fontWeight: 600,
      marginBottom: 15,
      fontSize: 20,
    },
    imageWrapper: {
      marginRight: 15,
      height: imgLoaded ? undefined : 270,
      width: imgLoaded ? undefined : 200,
      // borderRadius: 8,
      backgroundColor: imgLoaded ? undefined : '#d1d1d1'
      // backgroundColor: 'red',
    },
    image: {
      // height: 200,
      maxWidth: 200,
      maxHeight: 270,
      // marginTop: 15,
      // marginBottom: 15,
      // borderRadius: 8,
    },
    right: {
      height: "100%",
      // backgroundColor: 'pink',
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      // backgroundColor: 'red',
    },
    pointsIcon: {
      height: 20,
      marginRight: 5,
    },
    pointsText: {
      fontWeight: "600",
    },
    pointsWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
    },
    genre: {
      fontStyle: "italic",
    },
    numberOfWords: {
      fontStyle: "italic",
      marginTop: 5,
    },
    requiresPurchasePill: {
      padding: 8,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 6,
      backgroundColor: "#10884D",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 20,
    },
    dollarSignIcon: {
      filter: "invert(1)",
      height: 18,
      marginRight: 8,
    },
    requiresPurchaseText: {
      color: "#fff",
      fontWeight: "bold",
    },
  };

  let onImageLoad = () => {
    setImgLoaded(true)
  }

  return (
    <div style={styles.wrapper} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClick}>
      <div style={styles.imageWrapper}>
        <img src={book.coverUrl} style={styles.image} onLoad={onImageLoad} />
      </div>
      <div style={styles.right}>
        <div style={styles.title} className={noEllipsis ? '' : "bookDesc"}>{book.title}</div>

        {book.needsReview ? (
          <PendingApproval />
        ) : (
          <>
            <Points amount={points} extraText=" points" />
            <div style={{ height: 12 }} />
            <div style={styles.genre}>
              {genre} | {numberOfWords} words
            </div>
          </>
        )}
        {/* <div style={styles.numberOfWords}>{numberOfWords} words</div> */}
        {price ? (
          <div style={styles.requiresPurchasePill}>
            <img src={dollarSign} style={styles.dollarSignIcon} />
            <div style={styles.requiresPurchaseText}>Requires Purchase ( ${price} )</div>
          </div>
        ) : null}
        {book.explicit ? 
          <ExplicitTag />
        :null}
        {showLink ? 
        <a href={`${book.amazonUrl}`} style={{marginTop: 20}} target='_blank'>View on Amazon</a>
        :null}
      </div>
    </div>
  );
};

export default BookBox;
