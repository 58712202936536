import { Button, Table } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import Search from "../../../components/inputs/Search";
import PageTitle from "../../../components/PageTitle";
import { adminGetUsers } from "../../../firebase/users";
import getAgo from "../../../helpers/getAgo";
import { withRouter } from "../../../helpers/withRouter";


const cols = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: data => (
      <div>{data}</div>
    ),
  },
  {
    title: 'Amazon Username',
    dataIndex: 'amazonUsername',
    key: 'amazonUsername',
    render: data => (
      <div>{data}</div>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: data => (
      <div>{dayjs(new Date(data)).format('MMM DD, YYYY')}</div>
    ),
  },
];

class AdminUsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading: true,
      users: []
    };
  }

  componentDidMount(){
    this.loadUsers();
  }

  loadUsers = () => {
    adminGetUsers().then(users => {
      users.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      this.setState({users: users, loading: false})
    })
  }

  onClickRow = (record) => {
    this.props.navigate(`/admin/users/${record.id}`);
  };

  onChangeSearch = e => {
    this.setState({searchText: e.target.value})
  }

  onClickAddUser = () => {
    this.props.navigate(`/admin/users/new`)
  }

  render() {
    let tableData = this.state.users;

    tableData = tableData.filter(item => {
      if(this.state.searchText){
        let searchText = this.state.searchText.toLowerCase().trim();
        if(
          item.email.toLowerCase().includes(searchText) || 
          ((item || {}).amazonUsername || '').toLowerCase().includes(searchText)
        ){
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    });

    return (
      <AdminPageTemplate>
        <div style={styles.top}>
        <PageTitle title={`Users (${tableData.length})`} />
        <Search onChange={this.onChangeSearch} value={this.state.searchText} width={250} />
        <Button onClick={this.onClickAddUser}>+ Add User</Button>
        </div>
        <div style={styles.pageBody}>
          <Table
            columns={cols}
            loading={this.state.loading}
            dataSource={tableData}
            pagination={false}
            scroll={{
              y: "calc(100vh - 210px)",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.onClickRow(record);
                },
              };
            }}
            rowClassName="antd-table-row-clickable"
          />
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  pageBody: {
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  }
};

export default withRouter(AdminUsersPage);
