import { Button, Popconfirm, Input as AntInput, Alert, message } from "antd";
import React, { Component } from "react";
import colors from "../../colors";
import Input from "../../components/inputs/Input";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import { updateLoggedInUser } from "../../redux/functions";
import LeftButton from "./LeftButton";
import { connect } from "react-redux";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getAuth, updatePassword } from "firebase/auth";
import packageJSON from "../../../package.json";
import api from "../../firebase/api";

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: "account",
      password1: "",
      password2: "",
      isSavingPassword: false,
      user: {},
      message: "",
    };
  }

  componentDidMount() {
    this.setState({ 
      user: this.props.loggedInUser,
      alreadyHasAmazonUsername: this.props.loggedInUser.amazonUsername ? true : false
    });
  }

  onSendContact = () => {
    this.setState({ isSendingContact: true });
    api(`/contactSupport`, {
      body: {
        message: this.state.message,
      },
    }).then((res) => {
      this.setState({
        isSendingContact: false,
        hasSentContact: true,
        message: ''
      });
      setTimeout(() => {
        this.setState({ hasSentContact: false });
      }, 8000);
    });
  };

  onChangeUser = (key, val) => {
    this.setState({
      user: {
        ...this.state.user,
        [key]: val,
      },
    });
  };

  onSaveUser = async () => {
    this.setState({ userSaving: true });
    const userRef = doc(db, "users", this.state.user.id);
    await updateDoc(userRef, {
      email: this.state.user.email,
      amazonUsername: this.state.user.amazonUsername,
      name: this.state.user.name,
    });
    this.setState({
      userSaving: false,
      alreadyHasAmazonUsername: this.state.user.amazonUsername ? true : false,
    });
    message.success("Saved!");
  };

  onSavePassword = () => {
    const { password1, password2 } = this.state;
    if (password1 !== password2) return alert("passwords do not match");
    if (password1.length <= 5) return alert("Please enter a longer password");
    this.setState({ isSavingPassword: true });
    const auth = getAuth();
    const user = auth.currentUser;
    updatePassword(user, password1)
      .then(() => {
        message.success("Password Changed!");
        this.setState({
          isSavingPassword: false,
          password1: "",
          password2: "",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          alert("We need to confirm your identity before changing your password. Please log out and back in, then try changing your password again.");
          this.setState({ isSavingPassword: false });
        }
      });
  };

  confirmUnsub = () => {
    this.setState({ cancelSubLoading: true });
    api(`/cancelSubscription`).then((res) => {
      this.setState({ cancelSubLoading: false, hasUnsubscribed: true });
    });
  };

  render() {
    const { activeSection, userSaving, isSavingPassword, password1, password2, alreadyHasAmazonUsername } = this.state;
    const { email, amazonUsername, name } = this.state.user;

    const accountContent = (
      <>
        <div style={styles.title}>Account</div>
        <Input label="Email" value={email} onChange={(e) => this.onChangeUser("email", e.target.value)} inputProps={{ disabled: true }} />
        <Input label="Your Name" value={name} onChange={(e) => this.onChangeUser("name", e.target.value)} />
        <Input label="Amazon Username" value={amazonUsername} onChange={(e) => this.onChangeUser("amazonUsername", e.target.value)} inputProps={{disabled: alreadyHasAmazonUsername}}/>
        <div style={{ height: 7 }} />
      {!alreadyHasAmazonUsername && amazonUsername ? 
          <Popconfirm
            title={<div style={{width: 300}}>Once you set your amazon username, it cannot be changed. All reviews must be posted under this exact name. Are you sure?</div>}
            okText="Save"
            cancelText="No"
            // okButtonProps={{ danger: true }}
            onConfirm={this.onSaveUser}
          >
        <Button key='acctsaveb' type="primary"  loading={userSaving} disabled={userSaving}>
        Save
      </Button>
      </Popconfirm>
      :
      <Button key='acctsaveb' type="primary" onClick={this.onSaveUser} loading={userSaving} disabled={userSaving}>
        Save
      </Button>
      }

        <div style={styles.hr} />
        <div style={styles.title}>Change Password</div>
        <Input
          value={password1}
          onChange={(e) => this.setState({ password1: e.target.value })}
          label="New Password"
          inputProps={{ type: "password" }}
        />
        <Input
          value={password2}
          onChange={(e) => this.setState({ password2: e.target.value })}
          label="Confirm Password"
          inputProps={{ type: "password" }}
        />
        <div style={{ height: 7 }} />
        <Button type="primary" onClick={this.onSavePassword} disabled={!password1 || !password2 || isSavingPassword} loading={isSavingPassword}>
          Change Password
        </Button>
      </>
    );

    const billingContent = (
      <>
        <div style={styles.title}>Billing</div>
        {this.state.hasUnsubscribed || this.state.user.hasUnsubscribed ? (
          <>
            <div style={styles.subscriptionCancelledText}>Subscription Cancelled</div>
            <div style={{height: 10}} />
            <a href='https://bookgiveaway.com'>
              <Button>Re-Subscribe</Button>
            </a>
          </>
        ) : (
          <Popconfirm
            title="Are you sure you want to unsubscribe？"
            okText="Yes, Unsubscribe"
            cancelText="No"
            okButtonProps={{ danger: true }}
            onConfirm={this.confirmUnsub}
          >
            <Button danger size="small" loading={this.state.cancelSubLoading} disabled={this.state.cancelSubLoading}>
              Unsubscribe
            </Button>
          </Popconfirm>
        )}
        {/* <div style={{height: 20}} />
        <div style={styles.title}>Payment Method</div> */}
      </>
    );

    const contactContent = (
      <>
        <div style={styles.title}>Contact Us</div>
        <AntInput.TextArea
          placeholder="Your message..."
          rows={4}
          onChange={(e) => this.setState({ message: e.target.value })}
          value={this.state.message}
        />
        <div style={{ height: 10 }} />
        <Button type="primary" onClick={this.onSendContact} loading={this.state.isSendingContact} disabled={this.state.isSendingContact}>
          Send
        </Button>
        {this.state.hasSentContact ? (
          <Alert type="success" message="Message Sent. We will get back to you shortly." style={{ marginTop: 10 }} />
        ) : null}
      </>
    );

    return (
      <PageTemplate scroll>
        <PageTitle title="Settings" />
        <div style={styles.body}>
          <div style={styles.left}>
            <LeftButton section="account" activeSection={activeSection} onClick={(s) => this.setState({ activeSection: s })} />
            <LeftButton section="billing" activeSection={activeSection} onClick={(s) => this.setState({ activeSection: s })} />
            <LeftButton section="contact" activeSection={activeSection} onClick={(s) => this.setState({ activeSection: s })} />
          </div>
          <div style={styles.right}>
            <div style={styles.rightBox}>
              {activeSection === "account" ? accountContent : null}
              {activeSection === "billing" ? billingContent : null}
              {activeSection === "contact" ? contactContent : null}
            </div>
          </div>
        </div>
        <div style={styles.versionWrapper}>
          <div style={styles.version}>V {packageJSON.version}</div>
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  body: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
    flex: 1,
  },
  left: {
    // width: 200,
    marginRight: 50,
  },
  right: {
    flex: 1,
  },
  rightBox: {
    width: 500,
    backgroundColor: "#f7f7f7",
    borderRadius: 5,
    padding: 25,
    minHeight: 200,
  },
  title: {
    fontWeight: "600",
    fontSize: 20,
    marginBottom: 15,
  },
  hr: {
    height: 1,
    width: "100%",
    backgroundColor: colors.lightBG,
    marginTop: 20,
    marginBottom: 20,
  },
  version: {
    textAlign: "center",
    color: colors.lightText,
    fontSize: 12,
  },
  versionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    width: "100%",
  },
  scubscriptionCancelledText: {
    color: colors.lightText,
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(SettingsPage);
