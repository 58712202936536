import React from 'react';
import './firebase/index'; // leave this here, or firebase doesnt run

import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';

// import 'antd/dist/antd.css';
import './assets/antd-new.css'; // use this instead, recompiled with color var changes
import './assets/app.css';

import colors from './colors';
import firebaseApi from './firebase/api';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '5d0e188839e4dc5c5b7bf242d3a65d16',
  plugins: [new BugsnagPluginReact()]
})

global.colors = colors;
global.firebaseApi = firebaseApi;

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App  />
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
