import store from '../store';
import * as types from '../types';

export function setPointsTransactions(data){
  store.dispatch({
    type: types.SET_POINTS_TRANSACTIONS,
    payload: data
  })
}



