import { HourglassOutlined } from "@ant-design/icons";
import { Checkbox, Timeline, Steps, message } from "antd";
import React, { useState, useEffect } from "react";
import colors from "../../colors";
import { markAssignmentProgress } from "../../firebase/assignments";
import SubmitReviewModal from "../modals/SubmitReviewModal";
const { Step } = Steps;

const Dot = ({ value, onChange, itemKey, disabled, style}) => {
  return (
    <div style={{...styles.dotWrapper, ...style}}>
      <Checkbox checked={value} onChange={(e) => onChange(itemKey, e.target.checked)} disabled={disabled}  />
    </div>
  );
};

const ReviewTimeline = ({ assignment = {}, book={}, interactive }) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [a, setA] = useState({});
  useEffect(() => {
    if (assignment) setA(assignment);
  }, [assignment]);

  const updateCheck = (key, val) => {
    setA({
      ...a,
      [key]: val,
    });
  };

  const { hasAquired, hasRead, hasPostedReview, isComplete } = a;

  let step1Text = book.bookTypeVerifiedPurchase ? '1. Purchase Book on Amazon' : '1. Download Book';


  let current = 0;
  if (hasAquired) current = 1;
  if (hasRead) current = 2;
  if (hasPostedReview) current = 3;
  if (isComplete) current = 4;

  let onChangeItem = async function (key, val) {
    if (key === "hasPostedReview") {
      if (val === true) {
        setShowReviewModal(true);
      }
    }
    if (key === "hasAquired") {
      // sethasAquired(val);
      updateCheck("hasAquired", val);
      markAssignmentProgress(a.id, "hasAquired", val);
    }
    if (key === "hasRead") {
      updateCheck("hasRead", val);
      markAssignmentProgress(a.id, "hasRead", val);
    }
  };

  const onCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const onReviewSubmitSuccess = () => {
    updateCheck("hasPostedReview", true);
    setTimeout(() => {
      message.success("Review Submitted!")
    }, 300);
  };

  return (
    <>
      <SubmitReviewModal visible={showReviewModal} onCancel={onCloseReviewModal} onSubmitSuccess={onReviewSubmitSuccess} assignment={a}/>
      {interactive ? (
        <Timeline>
          <Timeline.Item
            color={hasAquired ? "green" : "blue"}
            dot={interactive ? <Dot disabled={hasRead} itemKey="hasAquired" onChange={onChangeItem} value={hasAquired} /> : undefined}
          >
            <span style={{ color: hasAquired ? colors.lightText : colors.text, fontWeight: !hasAquired ? "600" : undefined, fontSize: 20 }}>
              {hasAquired ? <s>{step1Text}</s> : step1Text}
            </span>
          </Timeline.Item>

          <Timeline.Item
            color={hasRead ? "green" : hasAquired ? "blue" : "gray"}
            dot={interactive ? <Dot disabled={hasAquired && !hasPostedReview ? false : true} itemKey="hasRead" onChange={onChangeItem} value={hasRead} /> : undefined}
          >
            <span
              style={{
                color: hasRead ? colors.lightText : hasAquired ? colors.text : colors.lightText,
                fontWeight: hasAquired && !hasRead ? "600" : undefined,
                fontSize: 20
              }}
            >
              {hasRead ? <s>{"2. Read Book"}</s> : "2. Read Book"}
            </span>
          </Timeline.Item>

          <Timeline.Item
            color={hasPostedReview ? "green" : hasRead ? "blue" : "gray"}
            dot={interactive ? <Dot disabled={hasRead && !hasPostedReview ? false : true} itemKey="hasPostedReview" onChange={onChangeItem} value={hasPostedReview} /> : undefined}
          >
            <span
              style={{
                color: hasPostedReview ? colors.lightText : hasRead ? colors.text : colors.lightText,
                fontWeight: hasRead && !hasPostedReview ? "600" : undefined,
                fontSize: 20
              }}
            >
              {hasPostedReview ? <s>{"3. Post Review to Amazon"}</s> : "3. Post Review to Amazon"}
            </span>
          </Timeline.Item>

          <Timeline.Item
            color={isComplete ? "green" : hasPostedReview ? "blue" : "gray"}
            dot={interactive ? <HourglassOutlined color={current > 2 ? colors.main : colors.lightText} style={{marginTop: 8}}/> : undefined}
          >
            <span
              style={{
                color: isComplete ? colors.lightText : hasPostedReview ? colors.text : colors.lightText,
                fontWeight: hasPostedReview && !isComplete ? "600" : undefined,
                fontSize: 20
              }}
            >
              {isComplete ? <s>{"4. Wait for Review Approval"}</s> : "4. Wait for Review Approval"}
            </span>
          </Timeline.Item>
        </Timeline>
      ) : (
        <Steps direction="vertical" size="small" current={current}>
          <Step title={step1Text} description="" />
          <Step title="Read Book" description="" />
          <Step title="Post Review to Amazon" description="" />
          <Step title="Wait for Review Approval" description="" />
        </Steps>
      )}
    </>
  );
};

const styles = {
  dotWrapper: {
    marginBottom: -5,
  },
};

export default ReviewTimeline;
