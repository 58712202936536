import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const AdminRoute = (props) => {
  if (props.loggedInUser.id) {
    if(props.loggedInUser.isAdmin){
      if(props.children){
        return props.children;
      }else{
        return <Navigate to="/admin/newbooks" />;
      }
    }else{
      return <Navigate to="/myassignments" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
  
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(AdminRoute);
