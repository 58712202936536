
import { db } from './index';
import { getAuth, getCur } from 'firebase/auth';
let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
let ngrok = undefined;
const apiBaseUrl = isLocalDev ? `${ngrok ? ngrok+'/bookreview-cf166/us-central1' : `http://${window.location.hostname}:5001/bookreview-cf166/us-central1`}` : 'https://us-central1-bookreview-cf166.cloudfunctions.net';
// if(isLocalDev) alert(apiBaseUrl)

export default function(path, options={}){
  return new Promise(async resolve => {
    let currentUserToken = '';
    let auth = getAuth();

    try{
      currentUserToken = await auth.currentUser.getIdToken();
    }catch(e){
      console.warn(e);
    }
    fetch(`${apiBaseUrl}${path}`, {
      method: 'POST',
      body: JSON.stringify(options.body || {}),
      headers: {
        Authorization: currentUserToken
      }
    })
    .then(result => result.json())
    .then(result => {
      resolve(result)
    })
  })

}