import store from '../store';
import * as types from '../types';

export function setMyActivity(data){
  store.dispatch({
    type: types.SET_MY_ACTIVITY,
    payload: data
  })
}





