import React, { Component } from "react";

import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import MyAssignmentsPage from "./MyAssignmentsPage";
import NotFound from "./NotFound";
import LoggedInUserRoute from "./LoggedInUserRoute";
import SettingsPage from "./SettingsPage";
import MyUploadsPage from "./MyUploadsPage";
import ViewAssignmentPage from "./ViewAssignmentPage";
import LibraryPage from "./LibraryPage/LibraryPage";
import ViewBookPage from "./ViewBookPage";
import UploadNewBookPage from "./UploadNewBookPage";
import ViewMyUploadPage from "./ViewMyUploadPage";
import AdminNewBooksPage from "./admin/AdminNewBooksPage";
import AdminRoute from "./AdminRoute";
import AdminUsersPage from "./admin/AdminUsersPage";
import AdminNewReviewsPage from "./admin/AdminNewReviewsPage";
import AdminViewNewReviewPage from "./admin/AdminViewNewReviewPage/AdminViewNewReviewPage";
import AdminViewNewBookPage from "./admin/AdminViewNewBookPage/AdminViewNewBookPage";
import AdminViewUserPage from "./admin/AdminViewUserPage/AdminViewUserPage";
import AdminAddUserPage from "./admin/AdminAddUserPage";
import AdminBooksPage from "./admin/AdminBooksPage/AdminBooksPage";
import AdminEditBookPage from "./admin/AdminEditBookPage/AdminEditBookPage";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <LoggedInUserRoute reverse>
                <LoginPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/myassignments"
            element={
              <LoggedInUserRoute>
                <MyAssignmentsPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/myuploads"
            element={
              <LoggedInUserRoute>
                <MyUploadsPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/viewbook/:id"
            element={
              <LoggedInUserRoute>
                <ViewBookPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/myuploads/new"
            element={
              <LoggedInUserRoute>
                <UploadNewBookPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/myuploads/:id"
            element={
              <LoggedInUserRoute>
                <ViewMyUploadPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/assignment/:id"
            element={
              <LoggedInUserRoute>
                <ViewAssignmentPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/library"
            element={
              <LoggedInUserRoute>
                <LibraryPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <LoggedInUserRoute>
                <SettingsPage />
              </LoggedInUserRoute>
            }
          />

          <Route
            exact
            path="/admin/newbooks"
            element={
              <AdminRoute>
                <AdminNewBooksPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/newreviews"
            element={
              <AdminRoute>
                <AdminNewReviewsPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/users"
            element={
              <AdminRoute>
                <AdminUsersPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/users/:id"
            element={
              <AdminRoute>
                <AdminViewUserPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/newreviews/:id"
            element={
              <AdminRoute>
                <AdminViewNewReviewPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/newbooks/:id"
            element={
              <AdminRoute>
                <AdminViewNewBookPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/books/:id"
            element={
              <AdminRoute>
                <AdminEditBookPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/users/new"
            element={
              <AdminRoute>
                <AdminAddUserPage />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/admin/books"
            element={
              <AdminRoute>
                <AdminBooksPage />
              </AdminRoute>
            }
          />

          <Route exact path="/admin" element={<AdminRoute />} />

          <Route exact path="/" element={<Navigate to="/login" />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(AppRouter);
