import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input as AntInput, message } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import EditBookClass from "../../../classes/EditBookClass";
import colors from "../../../colors";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import Input from "../../../components/inputs/Input";
import PageTitle from "../../../components/PageTitle";
import { adminEditBook, adminSubmitBookDecision, getBook } from "../../../firebase/books";
import { adminGetUser } from "../../../firebase/users";
import { withRouter } from "../../../helpers/withRouter";
import CheckItem from "../AdminViewNewBookPage/CheckItem";
import BookTypeRow from "../../UploadNewBookPage/BookTypeRow";
import FileBox from "../../UploadNewBookPage/FileBox";
import { UploadOutlined } from "@ant-design/icons";
import { getPendingReviews } from "../../../firebase/reviews";
import BookBox from "../../../components/BookBox";

class AdminEditBookPage extends EditBookClass {
  constructor(props) {
    super(props);
    this.state = {
      book: {},
      isUploadingCover: false,
      errorState: {},
    };
    this.isAdminEdit = true;
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    getBook(this.props.params.id).then((b) => {
      this.setState({
        book: { ...b },
        loading: false,
      });
      adminGetUser(b.userId).then((u) => {
        this.setState({ user: u });
      });
    });
  };

  submit = async () => {
    let msg = this.getError();
    if(msg) return alert(msg)
    this.setState({ submitting: true });
    let { list } = await getPendingReviews(this.state.book.id);
    if ((list || []).length > 0) {
      this.setState({ submitting: false });
      return alert(`This book has ${list.length} pending reviews. You cannot edit a book with pending reviews.`);
    }
    adminEditBook(this.state.book).then((res) => {
      message.success(`Edit Succesful`);
      this.props.navigate("/admin/books");
      this.setState({ submitting: false });
    });
  };

  viewUser = (id) => {
    this.props.navigate(`/admin/users/${id}`);
  };

  getError = () => {
    let message = '';
    if(this.state.book.bookTypeStandard && !this.state.book.pdfUrl) message = 'You must upload a PDF of the book first';
    if(this.state.book.bookTypeVerifiedPurchase && !this.state.book.price) message = 'You must set a price for your book';
    if(!this.state.book.amazonUrl ) message = 'You need to input the amazon URL first';
    if(!this.state.book.points) message = "You must set a value for the book's points";
    return message
  }

  render() {
    const { submitting, book = {}, loading, user = {}, isUploadingPdf, errorState } = this.state;
    const { coverUrl, pdfUrl } = book;

    return (
      <AdminPageTemplate maxWidth={700} scroll loading={loading}>
        <PageTitle title="Edit Book" />
        <div style={{ height: 20 }} />
        <div style={styles.body}>
          <BookBox showLink book={this.state.book} key={"bb2" + this.state.book.id} />
          <div style={{height: 20}} />
          <Input label="User" inline>
            {/* <div>{user.email}</div> */}
            <div>
              <Button size="small" icon={<UserOutlined />} onClick={() => this.viewUser(book.userId)}>
                {user.email}
              </Button>
            </div>
          </Input>

          <Input inline label="# Points" value={book.points || 0} onChange={this.onChangePoints} />
          <Input
            inline
            label="Amazon URL"
            onChange={(e) => this.onChangeBook("amazonUrl", e.target.value)}
            value={book.amazonUrl}
            error={errorState.amazonUrl}
          />
          <Input
            inputProps={{ type: "number", disabled: book.bookTypeVerifiedPurchase ? false : true }}
            inline
            label="Price"
            value={book.price}
            onChange={this.onChangePrice}
          />

          <div style={styles.title}>Book Types</div>

          <BookTypeRow
            title="Standard"
            desc="Upload a .pdf of your book for users to read"
            onChange={this.onChangeBookTypeStandard}
            value={book.bookTypeStandard}
            extra={
              <>
                {pdfUrl && !isUploadingPdf ? (
                  <FileBox url={pdfUrl} onClickChange={() => this.pdfInput.click()} />
                ) : (
                  <Button
                    size="small"
                    onClick={() => this.pdfInput.click()}
                    icon={<UploadOutlined />}
                    style={{ marginTop: 3 }}
                    loading={isUploadingPdf}
                    type={!pdfUrl && book.bookTypeStandard ? "primary" : undefined}
                    disabled={!book.bookTypeStandard}
                  >
                    Upload PDF
                  </Button>
                )}
                <input
                  type="file"
                  accept={"application/pdf"}
                  ref={(x) => (this.pdfInput = x)}
                  style={styles.hiddenInput}
                  onChange={(e) => this.addPdf(e.target.files[0])}
                ></input>
              </>
            }
          />
          <BookTypeRow title="Kindle Unlimited" onChange={this.onChangeBookTypeKindle} value={book.bookTypeKindle} />
          <BookTypeRow
            title="Verified Purchase"
            desc="Readers must purchase your book on Amazon, and read it on kindle"
            onChange={(x) => this.onChangeVerifiedPurchase(x)}
            value={book.bookTypeVerifiedPurchase}
          />

          <div style={{ height: 20 }} />
          <Button
            type={"primary" }
            onClick={this.submit}
            loading={submitting}
            disabled={submitting}
            style={{width: 120}}
          >
            Save
          </Button>
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  coverImage: {
    height: 250,
  },
  body: {
    flex: 1,
    overflow: "auto",
  },
  title: {
    fontWeight: "600",
    fontSize: 20,
    marginBottom: 5,
  },
  hr: {
    height: 1,
    backgroundColor: colors.lightBG,
    width: "100%",
    marginTop: 25,
    marginBottom: 25,
  },
  hiddenInput: {
    visibility: "hidden",
    display: "none",
  },
  title: {
    fontWeight: "700",
    fontSize: 20,
    marginTop: 30,
    marginBottom: 20,
  },
};

export default withRouter(AdminEditBookPage);
