import { Button, Dropdown, Menu, message, Modal, Popconfirm, Timeline } from "antd";
import React, { Component } from "react";
import colors from "../../colors";
import BookBox from "../../components/BookBox";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import BookDescription from "./BookDescription";
import { AmazonOutlined, CloseCircleOutlined, DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import CheckboxRow from "./CheckboxRow";
import ReviewTimeline from "../../components/ReviewTimeline/ReviewTimeline";
import { getAssignment } from "../../firebase/assignments";
import { getBook } from "../../firebase/books";
import { withRouter } from "../../helpers/withRouter";
import api from "../../firebase/api";

const initialState = {
  assignment: {},
  book: {},
  submitReviewModalVisible: true,
  cancelling: false,
  loading: true
};

class ViewAssignmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.params.id !== this.props.params.id){
      this.setState(initialState, () => this.loadData())
    }
  }

  onClickAmazon = () => {
    window.open(this.state.book.amazonUrl, "_blank");
  };

  loadData = async () => {
    let id = this.props.params.id;
    let a = await getAssignment(id);
    if (!a.isActive) {
      this.props.navigate("/myassignments");
    }
    getBook(a.bookId).then((res) => {
      this.setState({ book: res, assignment: a, loading: false });
    });
  };



  onConfirmCancel = () => {
    this.setState({ cancelling: true });
    return new Promise(resolve => {
      api(`/stopReadingBook`, {
        body: {
          assignmentId: this.props.params.id,
        },
      }).then((res) => {
        this.setState({ cancelling: false });
        this.props.navigate("/myassignments");
  
        setTimeout(() => {
          message.success("Assignment Cancelled");
        }, 200);
        resolve()
      });
    })
  };

  onClickPDF = () => {
    window.open(this.state.book.pdfUrl, '_blank');
  }

  onClickCancel = () => {
  
    Modal.confirm({
      title: "Cancel Assignment?",
      content: (
        <div>
          Are you sure you want to stop reading this book?
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: "Confirm",
      cancelText: "Nevermind",
      onOk: this.onConfirmCancel,
      maskClosable: true,
      confirmLoading: true
    });
  }

  render() {
    const { assignment, book } = this.state;

    const menu = (
      <Menu >
        <Menu.Item onClick={this.onClickCancel}>
          Cancel Assignment
        </Menu.Item>

      </Menu>
    );


    
    return (
      <PageTemplate scroll loading={this.state.loading}>
        <PageTitle title="View Assignment" />
        <div style={styles.pageTop}>
          <BookBox book={book} noEllipsis/>
          <BookDescription description={book.messageToReaders} authorName="Dean Barker" />
        </div>
        <div style={styles.buttonRow}>
          {/* {book.bookTypeVerifiedPurchase || book.bookTypeKindle ?    */}
          <Button type='primary' icon={<AmazonOutlined />} onClick={this.onClickAmazon} style={{marginRight: 15}}>
            Review on Amazon
          </Button>
          {/* // :null}              */}

          {book.bookTypeStandard ? 
            <Button icon={<FilePdfOutlined />} onClick={this.onClickPDF} style={{marginRight: 15}}>
              Book .pdf
            </Button>
          :null}

          <Dropdown overlay={menu}>
            <Button type='text'>More Options...</Button>
          </Dropdown>
        </div>
        <div style={styles.hruleWrapper}>
          <div style={styles.hrule}></div>
        </div>
        <div style={styles.bottom}>
          <div style={styles.title}>Progress</div>
          <div style={styles.subtitle}>Check off items as you complete them</div>
          <ReviewTimeline book={book} assignment={assignment} interactive={true} />
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  pageTop: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 20,
  },
  hrule: {
    height: 1,
    flex: 1,
    marginLeft: 50,
    marginRight: 50,
    backgroundColor: colors.lightBG,
    marginTop: 20,
    marginBottom: 20,
  },
  hruleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 28,
  },
  subtitle: {
    textAlign: "center",
    color: colors.lightText,
    marginBottom: 30,
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cancel: {
    color: colors.red,
    cursor: 'pointer'
  }
};

export default withRouter(ViewAssignmentPage);
