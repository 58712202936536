import { Alert, Button } from "antd";
import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import PageTemplate from "../../components/PageTemplate";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { connect } from "react-redux";
import illustration from "../../assets/img/illustrations/login.svg";
import { Modal } from "antd";
import Input from "../../components/inputs/Input";

const auth = getAuth();

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showForgotPasswordModal: false,
    };
  }

  componentDidMount() {}

  onChangeEmail = (t) => {
    this.setState({ email: (t+'' || '').toLowerCase().trim() });
  };

  onChangePassword = (t) => {
    this.setState({ password: t });
  };

  onSubmit = () => {
    this.setState({ submitting: true });
    const { email, password } = this.state;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // this.props.history.push('/myassignments')
        // this.setState({ submitting: false });
      })
      .catch((err) => {
        this.setState({ submitting: false });
        setTimeout(() => {
          alert("Login error. Please confirm your email and password are entered correctly.");
        }, 100);
      });
  };

  onSubmitForgotPassword = () => {
    this.setState({forgotPasswordLoading: true})
    sendPasswordResetEmail(auth, this.state.forgotPasswordEmail)
      .then(() => {
        this.setState({
          forgotPasswordLoading: false,
          forgotPasswordEmailSent: true,
          forgotPasswordEmail: ''
        })
      })
      .catch((error) => {
        console.log('error sending email', error)
      });

  };

  onKeyPress = (e) => {
    if (e.key === "Enter") this.onSubmit();
  };

  render() {
    const { showForgotPasswordModal, forgotPasswordLoading, forgotPasswordEmailSent } = this.state;

    return (
      <PageTemplate fadeIn>
        <div style={styles.wrapper}>
          <Modal
            title="Forgot Password"
            visible={showForgotPasswordModal}
            onCancel={() => this.setState({ showForgotPasswordModal: false })}
            footer={null}
          >
            <p>
              Enter your email address used for login, and we will send you instructions to reset your password.
            </p>
            <Input onChangeText={(x) => this.setState({ forgotPasswordEmail: x })} value={this.state.forgotPasswordEmail} inputProps={{placeholder: 'Your email...'}}/>
            <div style={styles.forgotPasswordButtonRow}>
              <Button type="primary" onClick={this.onSubmitForgotPassword} loading={forgotPasswordLoading}>Submit</Button>
              {forgotPasswordEmailSent ? 
                <Alert style={{marginLeft: 15}} message="Email Sent. Please check your spam folder as well as your inbox." />
              :null}
            </div>

          </Modal>


            <div style={styles.title}>Log In</div>
            <div style={styles.form}>
              <Input
                label="Email"
                onChangeText={this.onChangeEmail}
                value={this.state.email}
                inputProps={{
                  size: "large",
                  onKeyPress: this.onKeyPress,
                }}
                fullWidth
              />
              <Input
                label="Password"
                onChangeText={this.onChangePassword}
                value={this.state.password}
                inputProps={{
                  size: "large",
                  type: "password",
                  onKeyPress: this.onKeyPress,
                }}
                fullWidth
              />

              <Button
                type="primary"
                size="large"
                style={{ width: 200, marginBottom: 20, marginTop: 20 }}
                onClick={this.onSubmit}
                loading={this.state.submitting}
                disabled={this.state.submitting}
              >
                Log In
              </Button>
              <div style={styles.forgotPassword} className="fakeLink" onClick={() => this.setState({ showForgotPasswordModal: true })}>
                Forgot Password?
              </div>
       
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 30,
  },
  form: {
    width: 340,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    fontWeight: 600
  },
  illustration: {
    width: "100%",
  },
  left: {
    flex: 1,
    marginRight: 150,
    maxWidth: 400,
    minWidth: 200,
    position: "relative",
  },
  right: {},
  forgotPasswordButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70
  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(LoginPage);
