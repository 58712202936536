import React from 'react';
import colors from '../../colors';
import getAgo from '../../helpers/getAgo';

const ActivityItem = ({item={}, isUnread}) => {
  let agoStr = getAgo(item.createdAt);

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: 15,
      backgroundColor: isUnread ? colors.main+'20' : 'transparent',
      padding: 10,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 4,
      position: 'relative',
      overflow: 'visible'
    },
    text: {
      flex: 1,
      fontWeight: isUnread ? '600' : '400',
      position: 'relative',
      marginLeft: 5,
    },
    date: {
      color: colors.lightText,
      fontWeight: isUnread ? '600' : '400',
      paddingLeft: 15
    },
    dot: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: colors.red,
      top: 5,
      left: -20,
      position: 'absolute'
    }
  };

  return(
    <div style={styles.wrapper}>
      <div style={styles.text}>
        {isUnread ? <div style={styles.dot} /> : null}
        {item.text}
      </div>
      <div style={styles.date}>{agoStr}</div>
    </div>
  );
}



export default ActivityItem;