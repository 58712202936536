import React, { useState } from "react";
import colors from "../../colors";
import { Timeline } from "antd";
import ReviewTimeline from "../../components/ReviewTimeline/ReviewTimeline";


const MyAssignmentBox = ({ book, assignment, onClick }) => {
  let imgSrc = "https://prodimage.images-bn.com/lf?set=key%5Bresolve.pixelRatio%5D,value%5B1%5D&set=key%5Bresolve.width%5D,value%5B550%5D&set=key%5Bresolve.height%5D,value%5B10000%5D&set=key%5Bresolve.imageFit%5D,value%5Bcontainerwidth%5D&set=key%5Bresolve.allowImageUpscaling%5D,value%5B0%5D&product=path%5B/pimages/9780143111382_p0_v2%5D&call=url%5Bfile:common/decodeProduct.chain%5D";
  const [hover, setHover] = useState(false);

  const styles = {
    wrapper: {
      width: 500,
      // height: 330,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      // marginBottom: 25,
      margin: 15,
      marginTop:  15,
      marginBottom:  15,
      // marginTop: 15,
      // marginBottom: 15,
      cursor: assignment.isComplete ? undefined : "pointer",
      transition: ".1s",
      backgroundColor: hover ? "#f1f1f1" : "#f7f7f7",
      // borderRadius: 8,
      padding: 15,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'transparent',
      position: 'relative'
    },
    title: {
      maxWidth: 250,
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 20,
    },
    imageWrapper: {
      marginRight: 15,
      // backgroundColor: 'red',
    },
    image: {
      // height: 200,
      maxWidth: 200,
      maxHeight: 270,
      marginBottom: 6,
      // borderRadius: 8,
    },
    right: {
      height: "100%",
      // backgroundColor: 'pink',
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    completeCover: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // borderRadius: 8,
      backgroundColor: colors.lightText,
      opacity: .5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    completeText: {
      color: '#fff',
      fontWeight: '700',
      transform: 'rotate(25deg)',
      zIndex: 3,
      letterSpacing: 3,
      // position: 'absolute',
      // top: 20,
      fontSize: 48
    }
  };

  const onClickSelf = () => {
    if(assignment.isComplete){
      // do nothing
    }else{
      onClick();
    }
  }

  // hasPurchased = true;
  // hasRead = true;
  // hasPostedReview = true;
  // reviewApproved = true;

  return (
    <div style={styles.wrapper} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClickSelf}>
      <div style={styles.imageWrapper}>
        <img src={book.coverUrl} style={styles.image} />
      </div>
      <div style={styles.right}>
        <div style={styles.title} className="bookDesc">{book.title}</div>
        <ReviewTimeline book={book} assignment={assignment} />
      </div>
      {assignment.isComplete ? 
        <div style={styles.completeCover}>
          <div style={styles.completeText}>COMPLETE</div>
        </div>
      :null}
    </div>
  );
};

export default MyAssignmentBox;
