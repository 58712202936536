import React from "react";
import colors from "../../colors";
import notificationsIcon from '../../assets/img/bell.png';

const Bell = ({ number, onClick }) => {
  return (
    <div style={styles.notificationsWrapper} onClick={onClick}>
      {number ?
      <div style={styles.numberWrapper}>
        <div style={styles.numberText}>{number}</div>
        </div>
      : null}
      <img src={notificationsIcon} style={styles.notificationsIcon} />
    </div>
  );
};

const styles = {
  notificationsWrapper: {
    marginRight: 50,
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative',
    cursor: 'pointer'
  },
  notificationsIcon: {
    height: 28,
  },
  numberWrapper: {
    backgroundColor: colors.red,
    height: 22,
    width: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 11,
    position: 'absolute',
    top: -10,
    right: -10
  },
  numberText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 11
  }
};

export default Bell;
