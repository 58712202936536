import * as types from "../redux/types";
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc, limit } from "firebase/firestore";
import store from "../redux/store";
import { addActivity } from "./activity";
import { db } from "./index";

export function getPendingReviews(bookId) {
  return new Promise(async (resolve) => {
    let state = store.getState();

    const q = query(
      collection(db, "assignmentRequests"),
      where("bookId", "==", bookId),
      where("isActive", "==", true),
      where("authorId", "==", state.loggedInUser.id)
    );
    const querySnapshot = await getDocs(q);
    let pendingReviews = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pendingReviews.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    resolve({ list: pendingReviews });
  });
}

export function adminGetReviews() {
  return new Promise(async (resolve) => {
    const q = query(collection(db, "reviews"), where("finalized", "==", false));
    const querySnapshot = await getDocs(q);
    let reviews = [];

    querySnapshot.forEach((r) => {
      // doc.data() is never undefined for query doc snapshots
      reviews.push(
        new Promise(async (resolve2) => {
          const userRef = doc(db, "users", r.data().reviewerId);
          const userSnap = await getDoc(userRef);
          const bookRef = doc(db, "books", r.data().bookId);
          const bookSnap = await getDoc(bookRef);

          resolve2({
            ...r.data(),
            id: r.id,
            user: userSnap.data(),
            book: bookSnap.data(),
          });
        })
      );
    });
    Promise.all(reviews).then((res) => {
      resolve({ list: res });
    });
  });
}

export function adminGetUserReviews(userId, oldestDate, newestDate) {
  console.log('old', oldestDate)
  console.log('n ew', newestDate)
  return new Promise(async (resolve) => {
    const q = query(collection(db, "reviews"), 
    where("reviewerId", "==", userId), 
    where('approved', '==', true),
    where('finalizedAt', '>=', oldestDate),
    where('finalizedAt', '<=', newestDate),
    limit(300)
    );
    const querySnapshot = await getDocs(q);
    let reviews = [];

    querySnapshot.forEach((r) => {
      reviews.push(r.data())
    });

    resolve({ list: reviews, limitedMessage: reviews.length>298 ? 'Limited to the 300 most recent reviews.' : false});

  });
}

export function adminGetReview(id) {
  return new Promise(async (resolve) => {
    const docRef = doc(db, "reviews", id);
    const docSnap = await getDoc(docRef);
    resolve({
      ...docSnap.data(),
      id: docSnap.id,
    });
  });
}

export function getCompletedReviewsForBook(bookId) {
  return new Promise(async (resolve) => {
    let state = store.getState();
    const q = query(collection(db, "reviews"), where("approved", "==", true), where('bookId', '==', bookId), where('authorId', '==', state.loggedInUser.id));
    const querySnapshot = await getDocs(q);
    let reviews = [];
    querySnapshot.forEach((r) => {
      reviews.push({
        id: r.id,
        ...r.data()
      })
    })
    resolve({list: reviews})
  });
}
