import React from 'react';
import colors from '../../colors';

const AdminLink = ({label, url, selected, onClick}) => {

  const styles = {
    wrapper: {
      borderRadius: 4,
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: selected ? colors.main : 'transparent',
      color: selected ? '#fff' : colors.text,
      cursor: 'pointer',
      marginRight: 15
    }
  };

  return(
    <div style={styles.wrapper} onClick={onClick}>
      {label}
    </div>
  );
}


export default AdminLink;