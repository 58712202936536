import * as types from "../types";

const initialState = {
  lockedPoints: 0,
  list: [],
  loading: true
};

export function pointsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_POINTS_TRANSACTIONS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    default:
      return state;
  }
}
