import React, { Component } from "react";
import colors from "../colors";
import CustomFadeIn from "./CustomFadeIn";
import LeftNav from "./LeftNav";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import PageTitle from "./PageTitle";
import LoadingComponent from "./LoadingComponent";

const PageTemplate = ({ children, maxWidth, title, right, scroll, loading, fadeIn = true }) => {
  const loggedInUser = useSelector((state) => state.loggedInUser);

  const styles = {
    outsideWrapper: {
      height: "100vh",
      width: "100%",
      minWidth: 600,
      display: "flex",
      flexDirection: "row",
      paddingTop: 60,
      minHeight: "min-content",
      overflow: "hidden",
    },
    insideWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: loggedInUser.id ? 250 : 0,
      minHeight: "min-content",
      overflow: "hidden",
    },
    body: {
      padding: 20,
      paddingTop: 10,
      paddingBottom: 0,
      position: "relative",
      flex: 1,
      minHeight: "min-content",
      overflow: scroll ? 'auto' : "hidden",
      display: "flex",
      flexDirection: "column",
    },
    footer: {
      height: 300,
      backgroundColor: colors.lightBG,
      width: "100%",
      padding: 20,
    },
    top: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      paddingTop: 5,
    },
  };

  const body = fadeIn ? (
    loading ? (
      <LoadingComponent />
    ) : (
      <CustomFadeIn maxWidth={maxWidth || 1200}>
        <div style={styles.body} id="pageBody">
          {children}
        </div>
      </CustomFadeIn>
    )
  ) : (
    <div style={styles.body} id="pageBody">
      {loading ? <LoadingComponent absolute={true} /> : null}
      {loading ? null : children}
    </div>
  );

  return (
    <div style={styles.outsideWrapper}>
      <Navbar />
      {loggedInUser.id ? <LeftNav /> : null}
      <div style={styles.insideWrapper}>
        <div style={styles.top}>
          {title ? <PageTitle title={title} /> : null}
          {!title & right ? <div /> : null}
          <div style={styles.topRight}>{right ? right : null}</div>
        </div>
        <CustomFadeIn maxWidth={maxWidth || 1200}>
          {body}
        </CustomFadeIn>
        {/* <div style={styles.footer}>footer</div> */}
      </div>
    </div>
  );
};

export default PageTemplate;
