import React from 'react';
import icon from '../assets/img/logo_header.png';
import illustration from '../assets/img/illustrations/laptop.svg';

const MobileSplash = ({}) => {
  return(
    <div style={styles.wrapper}>
      <div style={styles.top}>
      <img src={icon} style={styles.icon} />

      </div>
      <div style={styles.bottom}>
      <div style={styles.message}>BookGiveaway is not available on mobile devices yet - please visit us on a computer!</div>
        <img src={illustration} style={styles.illustration}/>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20
  },
  icon: {
    width: '70%',
    maxHeight: 120,
  },
  message: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 40

  },
  illustration: {
    width: '70%',
    marginTop: 50
  },
  top: {

  },
  bottom: {
    flex: 1,
    paddingTop: 70,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
};

export default MobileSplash;