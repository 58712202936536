import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const LoggedInUserRoute = (props) => {
  if (props.reverse) {
    if (props.loggedInUser.id) {
      return <Navigate to="/myassignments" />;
    } else {
      return props.children;
    }
  } else {
    if (props.loggedInUser.id) {
      return props.children;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(LoggedInUserRoute);
