import * as types from '../redux/types';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc  } from "firebase/firestore";
import store from "../redux/store";
import { addActivity } from "./activity";
import {db} from './index';

export function getMyAssignments(opts){
  return new Promise(async resolve => {
    let state = store.getState();
    store.dispatch({type: types.FETCHING_ASSIGNMENTS, payload: opts})
    const q = query(collection(db, "assignments"), where("userId", "==", state.loggedInUser.id));
    const querySnapshot = await getDocs(q);
    let assignments = [];
    querySnapshot.forEach((a) => {
      // doc.data() is never undefined for query doc snapshots
      assignments.push(new Promise(async resolve => {
        const bookRef = doc(db, "books", a.data().bookId);
        const bookSnap = await getDoc(bookRef);
        resolve({
          ...a.data(),
          id: a.id,
          book: bookSnap.data()
        })
      }))
    });
    let active = [];
    let complete = [];
    Promise.all(assignments).then(res => {
      res = res.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
      res.forEach(item => {
        if(item.isActive){
          active.push(item)
        }
        if(!item.isActive && item.isComplete){
          complete.push(item)
        }
      })
      complete = complete.sort((a, b) => a.createdAt < b.createdAt)
      resolve({list: active, completedList: complete})
    })
  })
}

export function getAssignment(id){
  return new Promise(async resolve => {
    const docRef = doc(db, "assignments", id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      resolve({
        ...docSnap.data(),
        id: docSnap.id
      })
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })
}

export function markAssignmentProgress(assignmentId, key, value){
  return new Promise(async resolve => {
    const docRef = doc(db, "assignments", assignmentId);
    await updateDoc(docRef, {
      [key]: value
    });
    resolve()
  })
}
