import store from '../store';
import * as types from '../types';
export * from './books';
export * from './pointsTransactions';


export function updateLoggedInUser(user){
  store.dispatch({
    type: types.UPDATE_LOGGED_IN_USER,
    payload: user
  })
}

export function toggleActivitySlideout(isOpen){
  store.dispatch({
    type: types.TOGGLE_ACTIVITY_SLIDEOUT,
    payload: isOpen
  })
}

export function resetStore(){
  store.dispatch({
    type: types.RESET_STORE,
    payload: null
  })
}


