import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import colors from '../colors';

const LoadingComponent = ({absolute}) => {

  const [showLoader, setShowLoader] = useState(false);

  const styles = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 30
    },
    absoluteWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 2,
      height: 200,
      width: '50%',
      marginLeft: '25%',
      top: 20,
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: 5
    },
    loading: {
      color: '#fff',
      fontSize: 20,
      marginBottom: 20
    }
  };
  
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true)
    }, 500);
  }, []);

  if(!showLoader && absolute) return null;

  return(
    <div style={absolute ? styles.absoluteWrapper : styles.wrapper}>
      {absolute ? <div style={styles.loading}>Loading...</div> : null}
      {showLoader ? <ClipLoader color={absolute ? '#fff' : colors.lightText} /> : null}
    </div>
  );
}



export default LoadingComponent;