import * as types from "../types";

const initialState = {
  isOpen: false,
  list: [],
  unread: 0,
  loading: true,
  isFetching: false,
};

export function activityReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_ACTIVITY_SLIDEOUT:
      return {
        ...state,
        isOpen: action.payload !== undefined ? action.payload : !state.isOpen,
      };
    case types.SET_MY_ACTIVITY:
      return {
        ...state,
        list: action.payload.list,
        unread: action.payload.unread,
        isLoading: false,
        isFetching: false
      };
    case types.FETCHING_ACTIVITY:
      return {
        ...state,
        loading: true,
        isFetching: true,
      };
    default:
      return state;
  }
}
