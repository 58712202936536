import React from 'react';
import icon_18 from '../assets/img/18.png';

const ExplicitTag = ({}) => {
  return(
      <div style={styles.wrapper}>
        <img src={icon_18} style={styles.icon_18} />
        <div style={styles.text}>Explicit Content</div>
      </div>
  );
}

const styles = {
  wrapper: {
    padding: 8,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 6,
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: 20,
    marginBottom: 10,
    marginTop: 10
  },
  icon_18: {
    height: 22,
    width: 22,
    filter: 'invert(100%)'
  },
  text: {
    color: '#fff',
    marginLeft: 8,
    fontWeight: '600'
  }
};

export default ExplicitTag;