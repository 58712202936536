
import React, { Component } from "react";
import logo from "../../assets/img/logo_header.png";
import { connect, useSelector } from "react-redux";
import { Menu, Dropdown } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { MdMoreHoriz } from "react-icons/md";
import colors from "../../colors";
import { useLocation, useNavigate } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import AdminLink from "./AdminLink";
import { resetStore } from "../../redux/functions";


const auth = getAuth();

const AdminNavbar = ({}) => {
  const loggedInUser = useSelector((state) => state.loggedInUser);
  let navigate = useNavigate();
  let location = useLocation();

  let logout = () => {
    signOut(auth).then((res) => {
      // done
    });
  };


  const moreMenu = (
    <Menu>
      <div style={styles.username}>{loggedInUser.email}</div>
      <Menu.Item onClick={logout} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={styles.menuRow}>
          <span style={styles.iconWrapper}>
            <IoLogOut size={16} color={colors.text} />
          </span>
          <span style={styles.menuText}>Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );


  return (
    <div style={styles.wrapper}>
      <div style={styles.left}>
        <img src={logo} style={styles.logo} />
        <div style={styles.adminLabel}>ADMIN</div>
      </div>
      <div style={styles.right}>

        <AdminLink
          onClick={() => navigate('/admin/newbooks')}
          selected={location.pathname.includes('/admin/newbooks')}
          label="New Books"
        />
        
        <AdminLink
          onClick={() => navigate('/admin/books')}
          selected={location.pathname.includes('/admin/books')}
          label="Books"
        />

        <AdminLink
          onClick={() => navigate('/admin/newreviews')}
          selected={location.pathname.includes('/admin/newreviews')}
          label="Reviews"
        />

        <AdminLink
          onClick={() => navigate('/admin/users')}
          selected={location.pathname.includes('/admin/users')}
          label="Users"
        />
        <div style={{width: 20}} />

      <Dropdown overlay={moreMenu}>
        <div style={styles.moreWrapper}>
          <MdMoreHoriz color={colors.lightText} size={40} />
        </div>
      </Dropdown>

      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxShadow: "0 0 9px 3px rgba(0,0,0,.05)",
    height: 60,
    paddingLeft: 20,
    paddingRight: 20,
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    zIndex: 4,
  },
  logo: {
    height: 30,
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreWrapper: {
    // padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  username: {
    padding: 10,
    fontWeight: 700,
    color: colors.lightText,
  },
  menuRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  },

  adminLabel: {
    color: colors.lightText,
    marginLeft: 13,
    fontSize: 18
  }

};

export default AdminNavbar;
