import * as types from '../types';

const initialState = {
  userLoading: true
};



export function loggedInUserReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LOGGED_IN_USER:
      return ({
        ...action.payload,
        userLoading: false
      });
    default: return state;
  }
}