import { combineReducers } from 'redux';
import * as types from '../types';
import { activityReducer } from './activity';
import { loggedInUserReducer } from './loggedInUser';
import { myUploadsReducer, myAssignmentsReducer, libraryReducer } from './books';
import { pointsReducer } from './pointsTransactions';



const allReducers = combineReducers({
  loggedInUser: loggedInUserReducer,
  activity: activityReducer,
  myUploads: myUploadsReducer,
  library: libraryReducer,
  myAssignments: myAssignmentsReducer,
  pointsTransactions: pointsReducer
})

export default (state, action) => {
  if (action.type === 'RESET_STORE') {
    return allReducers(undefined, action)
  }
  return allReducers(state, action);
}