import { Button, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import colors from "../../../colors";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import Search from "../../../components/inputs/Search";
import PageTitle from "../../../components/PageTitle";
import { adminGetAllBooks } from "../../../firebase/books";
import { adminGetUser } from "../../../firebase/users";
import getAgo from "../../../helpers/getAgo";
import { withRouter } from "../../../helpers/withRouter";




class AdminBooksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading: true,
      books: [],
      userMap: {}
    };
  }

  componentDidMount(){
    this.loadBooks();
  }

  loadBooks = () => {
    adminGetAllBooks().then(books => {
      books.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      this.setState({books: books, loading: false})
    })
  }

  onChangeSearch = e => {
    this.setState({searchText: e.target.value})
  }

  onClickUser = data => {
    this.props.navigate(`/admin/users/${data}`);
  }

  loadUserPreview = userId => {
    adminGetUser(userId).then(u => {
      this.setState({
        userMap: {
          ...this.state.userMap,
          [userId]: u
        }
      })
    })
  }

  onClickRow = (record) => {
    this.props.navigate(`/admin/books/${record.id}`);
  }


  render() {
    let tableData = this.state.books;

    tableData = tableData.filter(item => {
      if(this.state.searchText){
        let searchText = this.state.searchText.toLowerCase().trim();
        if(
          ((item || {}).title || '').toLowerCase().includes(searchText)
        ){
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    });

    const cols = [
      {
        title: 'title',
        dataIndex: 'title',
        key: 'title',
        render: data => (
          <div>{data}</div>
        ),
      },
      {
        title: 'User',
        dataIndex: 'userId',
        key: 'userId',
        render: data => (
          <Tooltip title={(this.state.userMap[data] || {}).email || '...'} placement='topLeft'>
            <div style={styles.userLink} onClick={() => this.onClickUser(data)} onMouseEnter={() => this.loadUserPreview(data)}>View User</div>
          </Tooltip>
        ),
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: data => (
          <div>{dayjs(new Date(data)).format('MMM DD, YYYY')}</div>
        ),
      },
      {
        title: "Status",
        dataIndex: "id",
        key: "status1",
        render: (data, record, index) => <div>
          {record.approved ? <Tag color='green'>Approved</Tag> : null}
          {record.rejected ? <Tag color='red'>Rejected</Tag> : null}
          {record.needsReview ? <Tag color='yellow'>Pending Review</Tag> : null}
        </div>,
      },
    ];

    return (
      <AdminPageTemplate>
        <div style={styles.top}>
        <PageTitle title={`Books (${tableData.length})`} />
        <Search onChange={this.onChangeSearch} value={this.state.searchText} width={250} />
        {/* <Button onClick={this.onClickAddUser}>+ Add User</Button> */}
        <div />
        </div>
        <div style={styles.pageBody}>
          <Table
            columns={cols}
            loading={this.state.loading}
            dataSource={tableData}
            pagination={false}
            scroll={{
              y: "calc(100vh - 210px)",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.onClickRow(record);
                },
              };
            }}
            rowClassName="antd-table-row-clickable"
          />
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  pageBody: {
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  userLink: {
    color: colors.main,
    cursor: 'pointer',
    display: 'inline'
  }
};

export default withRouter(AdminBooksPage);
