import { Button, Tooltip } from "antd";
import React, { Component } from "react";
import logo from "../../assets/img/logo_header.png";
import moreIcon from "../../assets/img/more.png";
import { connect, useSelector } from "react-redux";
import { Menu, Dropdown } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { resetStore, toggleActivitySlideout, updateLoggedInUser } from "../../redux/functions";
import { PresetColorTypes } from "antd/lib/_util/colors";
import { MdMoreHoriz } from "react-icons/md";
import colors from "../../colors";
import { useNavigate } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import pointsIcon from '../../assets/img/points.png';
import lockIcon from '../../assets/img/lock.png';
import Bell from "./Bell";
import Points from "../Points";
import numToString from "../../helpers/numToString";

const auth = getAuth();

const Navbar = ({}) => {
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const unread = useSelector((state) => state.activity.unread);
  const pointsTransactions = useSelector((state) => state.pointsTransactions);
  let navigate = useNavigate();

  let logout = () => {
    signOut(auth).then((res) => {

    });
  };

  let onClickSettings = () => {
    navigate("/settings");
  };

  let onClickActivity = () => {
    toggleActivitySlideout()
  }

  const moreMenu = (
    <Menu>
      <div style={styles.username}>{loggedInUser.email}</div>
      <Menu.Item onClick={onClickSettings} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={styles.menuRow}>
          <span style={styles.iconWrapper}>
            <IoMdSettings size={16} color={colors.text} />
          </span>
          <span style={styles.menuText}>Settings</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={logout} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={styles.menuRow}>
          <span style={styles.iconWrapper}>
            <IoLogOut size={16} color={colors.text} />
          </span>
          <span style={styles.menuText}>Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  if (!loggedInUser.id) {
    return (
      <div style={styles.wrapper}>
        <div style={styles.left}>
          <img src={logo} style={styles.logo} />
        </div>
        <div style={styles.right}>
          <a href='https://bookgiveaway.com'>
            <Button type="primary">Sign Up</Button>
          </a>
        </div>
      </div>
    );
  }

  const lockedPointsToDisplay = numToString(pointsTransactions.lockedPoints);
  const pointsToDisplay = numToString(loggedInUser.points);
  return (
    <div style={styles.wrapper}>
      <div style={styles.left}>
        <img src={logo} style={styles.logo} />
      </div>
      <div style={styles.right}>



        <Tooltip color='#191919' title="Points Earned.">
          <span><Points amount={pointsToDisplay} /></span> {/* leave span here or tooltip doesnt work*/}
        </Tooltip>

        <Tooltip color='#191919' title={<span>When you request reviews, the points are locked until the review is complete. When the review is complete, the points will disappear. <br/><br/>If you cancel the review before it's complete, you will get your locked points back.</span>}>
          <div style={styles.frozenPointsWrapper}>
            <img src={lockIcon} style={styles.lockIcon} />
            <div style={styles.frozenPointsText}>{lockedPointsToDisplay}</div>
          </div>
        </Tooltip>

        <Bell number={unread} onClick={onClickActivity} />

        <Dropdown
          overlay={moreMenu}
          // onVisibleChange={this.handleVisibleChange}
          // visible={this.state.visible}
        >
          <div style={styles.moreWrapper}>
            <MdMoreHoriz color={colors.lightText} size={40} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxShadow: "0 0 9px 3px rgba(0,0,0,.05)",
    height: 60,
    paddingLeft: 20,
    paddingRight: 20,
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    zIndex: 4,
  },
  logo: {
    height: 30,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreWrapper: {
    // padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  username: {
    padding: 10,
    fontWeight: 700,
    color: colors.lightText,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  },
  menuRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuText: {},
  lockIcon: {
    height: 18,
    marginRight: 5
  },

  frozenPointsText: {
  },
  frozenPointsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 4,
    // backgroundColor: colors.frozen,
    marginRight: 30,
    borderRadius: 5,
    opacity: .3,
    cursor: 'default'
  },

};

export default Navbar;
