import React, { useState } from "react";

import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDoc, doc, setDoc } from "firebase/firestore";

import store from "../redux/store";
import { resetStore, updateLoggedInUser } from "../redux/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCGzpfnUn8288qc0Z6Zyd7IZ4_fD5A281Q",
  authDomain: "bookreview-cf166.firebaseapp.com",
  projectId: "bookreview-cf166",
  storageBucket: "bookreview-cf166.appspot.com",
  messagingSenderId: "326259804282",
  appId: "1:326259804282:web:d944d89a915dd78a3453c3",
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

const auth = getAuth();
// handle user login or logout, or things that need to happen on load based on if there is a logged in user
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const linkedUser = await getDoc(doc(db, "users", user.uid));
    updateLoggedInUser({
      ...linkedUser.data(),
      id: linkedUser.id,
    });
    // // ---- you can do stuff that requires logged in user here ----
  } else {
    // no one is signed in
    resetStore();
    setTimeout(() => {
      updateLoggedInUser({ loggedIn: false });
    }, 100);

  }
});

export { db };
