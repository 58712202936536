import * as types from "../redux/types";
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import store from "../redux/store";
import { addActivity } from "./activity";
import { db } from "./index";

export function addBook(data) {
  return new Promise(async (resolve) => {
    const docRef = await addDoc(collection(db, "books"), {
      ...data,
      createdAt: Date.now(),
      userId: store.getState().loggedInUser.id,
      needsReview: true,
      isActive: true,
    });
    await addActivity("You submitted a book: " + data.title, { bookId: docRef.id }, false);
    resolve();
  });
}

export function getMyUploads(opts) {
  return new Promise(async (resolve) => {
    let state = store.getState();
    store.dispatch({ type: types.FETCHING_UPLOADS, payload: opts });
    const q = query(collection(db, "books"), where("userId", "==", state.loggedInUser.id), where("isActive", "==", true));
    const querySnapshot = await getDocs(q);
    let books = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      books.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    books = books.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    resolve({ list: books });
  });
}

export function adminGetUserBooks(userId) {
  return new Promise(async (resolve) => {
    const q = query(collection(db, "books"), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    let books = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      books.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    books = books.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    resolve({ list: books });
  });
}

export function adminGetNewBooks() {
  return new Promise(async (resolve) => {
    const q = query(collection(db, "books"), where("needsReview", "==", true));
    const querySnapshot = await getDocs(q);
    let books = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      books.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    books = books.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    resolve({ list: books });
  });
}

export function getBook(bookId) {
  return new Promise(async (resolve) => {
    const docRef = doc(db, "books", bookId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      resolve({
        ...docSnap.data(),
        id: docSnap.id,
      });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  });
}

export function adminSubmitBookDecision(book = {}) {
  return new Promise(async (resolve) => {
    const docRef = doc(db, "books", book.id);
    await updateDoc(docRef, {
      ...book,
      requiresPurchase: book.price ? true : false,
      needsReview: false,
      rejected: book.approved ? false : true,
      isActive: book.approved ? true : false,
    });
    await addActivity(
      `Your book [${book.title}] has been ${book.approved ? "Approved!" : `Rejected. Reason: ${book.approvalReason}`}`,
      { bookId: docRef.id, userId: book.userId },
      true
    );
    resolve();
  });
}

export function adminEditBook(book = {}) {
  return new Promise(async (resolve) => {
    const docRef = doc(db, "books", book.id);
    await updateDoc(docRef, {
      ...book,
      requiresPurchase: book.price ? true : false,
    });
    await addActivity(
      `An admin edited some information on your book: [${book.title}]`,
      { bookId: docRef.id, userId: book.userId },
      false
    );
    resolve();
  });
}

export function editBookMessage(book = {}) {
  return new Promise(async (resolve) => {
    const docRef = doc(db, "books", book.id);
    await updateDoc(docRef, {
      messageToReaders: book.messageToReaders,
    });
    resolve();
  });
}

export function adminGetAllBooks(){
  return new Promise(async resolve => {
    const q = query(collection(db, "books"));
    const querySnapshot = await getDocs(q);
    let books = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      books.push(doc.data())
    });
    resolve(books)
  })
}
