import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import colors from '../../../colors';

const CheckItem = ({ label, checked }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5
    },
    label: {
      marginLeft: 7,
      fontWeight: checked ? '700' : '400',
      color: checked ? colors.text : colors.lightText
    },
    icon: {
      color: checked ? colors.green : colors.lightText,
    }
  };
  return(
    <div style={styles.wrapper}>
      {checked ? 
      <CheckOutlined style={styles.icon} />
      :
      <CloseOutlined style={styles.icon} />
      }
      <div style={styles.label}>{label}</div>
    </div>
  );
}



export default CheckItem;