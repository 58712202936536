import React from 'react';

const IllustrationMessage = ({title, desc, illustration}) => {
  return(
    <div style={styles.wrapper}>
    <div style={styles.title}>{title}</div>
    <div style={styles.subtitle}>{desc}</div>
    <img src={illustration} style={styles.illustration} />
  </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 100
  },
  title: {
    marginBottom: 10,
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subtitle: {
    marginBottom: 30,
    textAlign: 'center'

  },
  illustration: {
    height: 300
  }
};

export default IllustrationMessage;