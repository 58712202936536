import React, { Component } from "react";
import { addFile } from "../firebase/files";
import { Alert } from 'antd'

class EditBookClass extends Component {

  addPdf = (file) => {
    this.setState({ isUploadingPdf: true });
    addFile(file, "pdf").then((res) => {
      this.setState({
        isUploadingPdf: false,
      });
      this.onChangeBook("pdfUrl", res);
    });
  };

  onChangeVerifiedPurchase = (x) => {
    this.setState({
      book: {
        ...this.state.book,
        bookTypeVerifiedPurchase: x,
        bookTypeStandard: false,
        bookTypeKindle: false,
      },
    });
  };

  onChangeBookTypeStandard = (x) => {
    this.setState({
      book: {
        ...this.state.book,
        bookTypeVerifiedPurchase: false,
        bookTypeStandard: x,
        price: this.isAdminEdit ? '' : this.state.book.price
      },
    });
  };

  onChangeBookTypeKindle = (x) => {
    this.setState({
      book: {
        ...this.state.book,
        bookTypeKindle: x,
        bookTypeVerifiedPurchase: false,
        price: this.isAdminEdit ? '' : this.state.book.price
      },
    });
  };

  onChangeBook = (key, val) => {
    this.setState({
      book: {
        ...this.state.book,
        [key]: val,
      },
    });
  };

  onChangePoints = (e) => {
    let val = e.target.value;
    if (isNaN(val) && val !== undefined && val !== "") return;
    if (!val) val = 0;
    if (val > 999999) return;
    if (/^[0-9]*$/.test(val) === false) return;
    if (isNaN(val) === false && typeof val === "string") val = parseInt(val);
    this.onChangeBook("points", val);
  };

  onChangePrice = (e) => {
    let val = e.target.value;
    if (!val) return this.onChangeBook("price", 0);
    if (isNaN(val) === false && typeof val === "string") val = parseFloat(val);
    var r = /^\$?[0-9]+\.?[0-9]*$/;
    if (!r.test(val)) return;
    this.onChangeBook("price", val);
  };

  getErrorStateAlert = () => {
    let disMap = {
      title: 'Title',
      author: 'Author',
      amazonUrl: 'Amazon URL',
      genre: 'Genre',
      coverUrl: 'Book Cover Photo',
      pdfUrl: 'Upload Book PDF'
    }
    let errorDisList = []
    Object.keys(this.state.errorState).forEach(k => {
      errorDisList.push(disMap[k])
    })
    if(JSON.stringify(this.state.errorState) === '{}'){
      return null
    }else{
      let errorMsg = `Fields missing: ${errorDisList.toString().replaceAll(',', ', ')}`
      return<Alert showIcon style={{marginTop: 15}} type="warning" message={errorMsg} />
    }  
  }

}

export default EditBookClass;
