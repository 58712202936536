import React from 'react';
import searchIcon from '../../assets/img/search.png'
import colors from '../../colors';

const Search = ({onChange, value, width}) => {

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: width
  
    },
    input: {
      flex: 1,
      backgroundColor: 'transparent',
      fontSize: 18,
      backgroundColor: colors.lightBG,
      height: 44,
      borderRadius: 22,
      paddingLeft: 45,
      borderColor: 'transparent'
  
    },
    searchIcon: {
      height: 18,
      marginRight: -36,
      zIndex: 2
    }
  };

  return(
    <div style={styles.wrapper}>
      <img src={searchIcon} style={styles.searchIcon} />
      <input onChange={onChange} value={value} style={styles.input} />
    </div>
  );
}



export default Search;