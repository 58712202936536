import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import logo from '../assets/img/logo_header.png';
import notFoundIllustration from '../assets/img/illustrations/404.svg';

const NotFound = ({}) => {
  let location = useLocation();
  return (
    <div style={styles.wrapper}>
      <img src={logo} style={styles.logo} />
      <img src={notFoundIllustration} style={styles.illustration} />
      <div style={styles.message}>Page Not Found: <strong>{location.pathname}</strong></div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 20,
  },
  message: {
    fontSize: 36,
    textAlign: "center",
  },
  logo: {
    height: 30,
    marginBottom: 40
  },
  illustration: {
    padding: 50,
    height: 400
  }
};

export default NotFound;
