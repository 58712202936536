import React, { Component } from "react";
import SplashScreen from "./components/SplashScreen";
import AppRouter from "./routes";
import { connect } from "react-redux";
import ActivitySlideout from "./components/ActivitySlideout";
import { message } from "antd";
import DataLoader from "./components/DataLoader";
import { isMobile } from 'react-device-detect';
import MobileSplash from "./components/MobileSplash";
import SubscriptionCover from "./components/SubscriptionCover";

message.config({
  top: 35,
});



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loggedInUser } = this.props;
    let isLoading = loggedInUser.userLoading;

    if(isMobile) return <MobileSplash />

    return (
      <>
        {isLoading ? (
          <SplashScreen key="splash1" show={true} />
        ) : (
          <>
            <DataLoader />
            <AppRouter />
            <ActivitySlideout />
            <SubscriptionCover />
          </>
        )}
      </>
    );
  }
}

const styles = {};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(App);
