import { Modal, Input as AntInput, Select } from "antd";
import React, { Component } from "react";
import Points from "../../Points";
import { connect } from 'react-redux';
import api from "../../../firebase/api";

const initialState = {
  numberOfReviews: 0,
  loading: false
};

class GetReviewsModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  renderAmountOptions = () => {
    let maxReviews = 0;
    let bookPoints = this.props.book.points;
    let userPoints = this.props.loggedInUser.points;
    if(bookPoints && userPoints){
      maxReviews = userPoints / bookPoints;
      maxReviews = Math.floor(maxReviews);
      if(maxReviews > 25) maxReviews = 25;
    }

    let options = [];
    let i = 0;
    while (i < maxReviews) {
      i++;
      options.push(<Select.Option value={i}>{i}</Select.Option>);
    }
    return options;
  };

  onChangeNumberOfReviews = (amount) => {
    this.setState({ numberOfReviews: amount });
  };

  submit = () => {
    this.setState({loading: true})
    api(`/requestReviews`, {
      body: {
        bookId: this.props.book.id,
        numberOfReviews: this.state.numberOfReviews
      }
    }).then(res => {
      if(res.status === 'success'){
        this.setState({loading: false});
        this.props.onSubmitSuccess();
      }else{
        this.setState({loading: false});
        alert(res.message);
      }
    })
  }

  render() {
    const { visible, onSubmit, onCancel } = this.props;
    const { numberOfReviews } = this.state;
    const totalCost = this.props.book.points * numberOfReviews;
    return (
      <Modal
        title="Get Reviews"
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{
          disabled: numberOfReviews ? false  : true,
          loading: this.state.loading
        }}
        onOk={this.submit}
        okText={`Get ${this.state.numberOfReviews} review${numberOfReviews === 1 ? "" : "s"}!`}
      >
        <div>
          How many reviews would you like to get? Your points will be frozen while the reviews are in progress. You can cancel the reviews at any time
          before they are completed, and get your points back.
          <div style={styles.reviewAmountRow}>
            <Select onChange={this.onChangeNumberOfReviews} value={this.state.numberOfReviews} style={{ width: 80 }}>
              {this.renderAmountOptions()}
            </Select>
            <div style={styles.reviewAmountLabel}>reviews</div>
          </div>
          <div style={styles.costRow}>
            <div style={styles.costLabel}>Total Cost:</div>
            <Points amount={totalCost} />
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = {
  reviewAmountRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  reviewAmountLabel: {
    marginLeft: 8,
  },
  costRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 12,
  },
  costLabel: {
    marginRight: 8,
    fontWeight: "bold",
  },
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(GetReviewsModal);
