import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import store from "../redux/store";
import {db} from './index';

export function adminGetUsers(){
  return new Promise(async resolve => {
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);
    let users = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      users.push(doc.data())
    });
    resolve(users)
  })
}

export function adminGetUser(userId){
  return new Promise(async resolve => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    let notes = '';
    const noteQuery = query(collection(db, "adminUserNotes"), where("userId", "==", userId));
    const noteQuerySnap = await getDocs(noteQuery);
    noteQuerySnap.forEach((noteDoc) => {
      if(noteDoc.exists()){
        notes = noteDoc.data().notes;
      }
    })
    if (docSnap.exists()) {
      resolve({
        ...docSnap.data(),
        notes: notes
      })
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })
}

export function adminEditUser(userId, data){
  return new Promise(async resolve => {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, {...data, notes: ''});
    const noteQuery = query(collection(db, "adminUserNotes"), where("userId", "==", userId));
    const noteQuerySnap = await getDocs(noteQuery);
    let noteExists = false;
    noteQuerySnap.forEach(async (noteDoc) => {
      if(noteDoc.exists()){
        noteExists = true;
        const noteDocRef = doc(db, "adminUserNotes", noteDoc.id);
        await updateDoc(noteDocRef, {notes: data.notes});
      }
    });
    if(noteExists === false){
      await addDoc(collection(db, "adminUserNotes"), {
        notes: data.notes,
        userId: userId
      });
    }
    resolve()
  })
}

export function getMyUser(){
  return new Promise(async resolve => {
    const state = store.getState();
    const docRef = doc(db, "users", state.loggedInUser.id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      resolve(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })
}