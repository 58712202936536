import React, { Component } from "react";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import { withRouter } from "../../../helpers/withRouter";
import PageTitle from "../../../components/PageTitle";
import { Button, Radio, Switch, Table, DatePicker, Input as AntInput, message, Checkbox, Tag } from "antd";
import Input from "../../../components/inputs/Input";
import colors from "../../../colors";
import getAgo from "../../../helpers/getAgo";
import moment from "moment";
import { adminEditUser, adminGetUser } from "../../../firebase/users";
import api from "../../../firebase/api";
import { adminGetUserReviews } from "../../../firebase/reviews";
import { adminGetUserBooks } from "../../../firebase/books";
import dayjs from "dayjs";
import { connect } from "react-redux";

const one_day = 1000 * 60 * 60 * 24;
const { RangePicker } = DatePicker;

const superAdmins = ['deanbarker44@gmail.com', 'chasewhassen@gmail.com'];

const cols = [
  {
    title: "Book Title",
    dataIndex: "bookTitleAdminPanel",
    key: "bookTitleAdminPanel",
    render: (data) => <div>{data}</div>,
  },
  {
    title: "Submitted",
    dataIndex: "finalizedAt",
    key: "finalizedAt",
    render: (data) => (
      <div>
        {getAgo(data)}
        <span style={styles.lightDate}>({dayjs(new Date(data)).format("MMM DD, YYYY hh:mm a")})</span>
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "approved",
    key: "approved",
    render: (data) => <div>{data ? <Tag color="green">Approved</Tag> : "?"}</div>,
  },
];

const bookTableCols = [
  {
    title: "Book Title",
    dataIndex: "title",
    key: "title",
    render: (data) => <div>{data}</div>,
  },
  {
    title: "Submitted",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data) => (
      <div>
        {getAgo(data)}
        <span style={styles.lightDate}>({dayjs(new Date(data)).format("MMM DD, YYYY hh:mm a")})</span>
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "id",
    key: "status1",
    render: (data, record, index) => (
      <div>
        {record.approved ? <Tag color="green">Approved</Tag> : null}
        {record.rejected ? <Tag color="red">Rejected</Tag> : null}
        {record.needsReview ? <Tag color="yellow">Pending Review</Tag> : null}
      </div>
    ),
  },
];

class AdminViewUserPage extends Component {
  constructor(props) {
    super(props);
    let now = new Date().getTime();
    let ago = new Date().getTime();
    ago = ago - one_day * 7;
    this.state = {
      section: "info",
      email: "",
      amazonUsername: "",
      subscriptionActive: true,
      stripeSubscriptionId: "asidjfb",
      presetRange: "",
      points: 0,
      dateRange: [moment(now), moment(ago)],
      loading: true,
      user: {},
      userReviews: [],
      books: [],
      loadingBooks: true,
    };
  }

  componentDidMount() {
    this.loadUser();
    this.loadBooks();
  }

  loadUser = () => {
    adminGetUser(this.props.params.id).then((user) => {
      this.setState({
        user,
        loading: false,
      });
    });
  };

  onChangeUserData = (key, val) => {
    this.setState({
      user: {
        ...this.state.user,
        [key]: val,
      },
      hasChanged: true,
    });
  };

  onChangeDateRange = (val) => {
    this.setState(
      {
        dateRange: [val[0].startOf("day"), val[1].endOf("day")],
        presetRange: "none",
      },
      () => this.loadReviews()
    );
  };

  onClickPast2Weeks = () => {
    let now = new Date().getTime();
    let ago = new Date().getTime();
    ago = ago - one_day * 14;
    this.setState(
      {
        dateRange: [moment(ago).startOf("day"), moment(now).endOf("day")],
        presetRange: "past2weeks",
      },
      this.loadReviews()
    );
  };

  onClickPastWeek = () => {
    let now = new Date().getTime();
    let ago = new Date().getTime();
    ago = ago - one_day * 7;
    this.setState(
      {
        dateRange: [moment(ago).startOf("day"), moment(now).endOf("day")],
        presetRange: "pastWeek",
      },
      this.loadReviews()
    );
  };

  loadReviews = () => {
    setTimeout(() => {
      console.log("loading reviews");
      this.setState({ loadingReviews: true });
      let newest = this.state.dateRange[1].utc().valueOf();
      let oldest = this.state.dateRange[0].utc().valueOf();
      adminGetUserReviews(this.state.user.id, oldest, newest).then((res) => {
        this.setState({
          userReviews: res.list,
          loadingReviews: false,
        });
        console.log("list len", res.list.length);
        if (res.limitedMessage) alert(res.limitedMessage);
      }, 200);
    });
  };

  loadBooks = () => {
    adminGetUserBooks(this.props.params.id).then((res) => {
      this.setState({
        books: res.list,
        loadingBooks: false,
      });
    });
  };

  onChangeSection = (e) => {
    this.setState({ section: e.target.value });
    if (e.target.value === "reviews") this.onClickPastWeek();
  };

  onChangePoints = (val) => {
    if (isNaN(val) && val !== undefined && val !== "") return;
    if (!val) val = 0;
    if (val > 999999) return;
    if (/^[0-9]*$/.test(val) === false) return;
    if (isNaN(val) === false && typeof val === "string") val = parseInt(val);
    this.setState({ hasChanged: true, user: { ...this.state.user, points: val } });
  };

  submitPassword = () => {
    if (this.state.password !== this.state.cPassword) return alert("Passwords do not match");
    this.setState({ passwordLoading: true });
    api("/adminChangePassword", {
      body: {
        userId: this.props.params.id,
        newPassword: this.state.password,
      },
    }).then((res) => {
      this.setState({
        passwordLoading: false,
        password: "",
        cPassword: "",
      });
      message.success("Changed Password!");
    });
  };

  onClickRow = (record) => {};

  save = () => {
    this.setState({ saving: true });
    adminEditUser(this.props.params.id, this.state.user).then((res) => {
      this.setState({
        saving: false,
        hasChanged: false,
      });
      message.success("Edited User");
    });
  };

  render() {
    const { presetRange, user } = this.state;

    let tableData = this.state.userReviews;
    let bookTableData = this.state.books;

    const titleSection = (
      <div style={styles.titleRow}>
        <PageTitle title="View User" />
        <Radio.Group style={styles.sectionSelect} value={this.state.section} onChange={this.onChangeSection}>
          <Radio.Button value="info">Info</Radio.Button>
          <Radio.Button value="reviews">Reviews</Radio.Button>
          <Radio.Button value="books">Books</Radio.Button>
        </Radio.Group>
      </div>
    );

    const infoContent = (
      <div style={styles.infoWrapper}>
        <div style={styles.info}>
          {titleSection}
          <div style={{ height: 20 }} />
          <Input
            inline
            label="Email"
            inputProps={{ disabled: true }} // NOTE if you want to edit email, you must change it in firebase auth also, not just this user.
            value={user.email}
            onChange={(e) => this.onChangeUserData("email", e.target.value)}
          />
          <Input
            inline
            label="Amazon Username"
            value={user.amazonUsername}
            onChange={(e) => this.onChangeUserData("amazonUsername", e.target.value)}
          />
          <Input inline label="Name" value={user.name} onChange={(e) => this.onChangeUserData("name", e.target.value)} />
          <Input inline label="Subscription Active?">
            <Switch
              checked={user.subscriptionDisabled ? false : true}
              onChange={(x) => this.onChangeUserData("subscriptionDisabled", x ? false : true)}
            />
          </Input>
          <Input inline label="Subscription Expires At">
            <DatePicker
              onChange={(m) => this.onChangeUserData("subscriptionExpiresAt", m.utc().valueOf())}
              value={user.subscriptionExpiresAt ? moment(user.subscriptionExpiresAt) : null}
            />
          </Input>

          <Input inline label="Is Admin?">
            {superAdmins.includes(this.props.loggedInUser.email) && superAdmins.includes(user.email) === false ? (
              <Switch checked={user.isAdmin ? true : false} onChange={(x) => this.onChangeUserData("isAdmin", x ? true : false)} />
              ) : (
              <div>{user.isAdmin ? "Yes" : "No"}</div>
            )}
          </Input>

          <Input inline label="Stripe Subscription ID" inputProps={{ disabled: true }} value={user.stripeSubscriptionId} />
          <Input inline label="Subscribed On" inputProps={{ disabled: true }}>
            <DatePicker disabled value={user.subscribedOn ? moment(user.subscribedOn) : null} />
          </Input>
          <Input inline label="Subscription Type" inputProps={{ disabled: true }} value={user.subscriptionType} />
          <Input inline label="Points Balance" onChangeText={this.onChangePoints} value={user.points === undefined ? 0 : user.points} />
          <Input inline label="User Notes" tooltip="Only admins can see this. Keep track of anything you want here">
            <AntInput.TextArea rows={4} value={user.notes} onChange={(e) => this.onChangeUserData("notes", e.target.value)} />
          </Input>
          <Button
            style={{ marginTop: 15, width: 150 }}
            type="primary"
            onClick={this.save}
            loading={this.state.saving}
            disabled={!this.state.hasChanged || this.state.saving}
          >
            Save User
          </Button>
          <div style={styles.hr} />
          <div style={styles.title}>Password Reset</div>
          <Input inline label="New Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
          <Input inline label="Confirm Password" value={this.state.cPassword} onChange={(e) => this.setState({ cPassword: e.target.value })} />
          <Button
            style={{ marginTop: 20 }}
            type="primary"
            onClick={this.submitPassword}
            loading={this.state.passwordLoading}
            disabled={this.state.passwordLoading || !this.state.password || !this.state.cPassword}
          >
            Submit Password Change
          </Button>
        </div>
      </div>
    );

    const reviewsContent = (
      <div style={styles.tableWrapper}>
        <div style={styles.tableTopWrapper}>
          <div style={styles.tableTopInside}>
            {titleSection}
            <div style={styles.tableControlsRow}>
              <div style={styles.tableButtons}>
                <Button type={presetRange === "pastWeek" ? "primary" : undefined} onClick={this.onClickPastWeek}>
                  Past Week
                </Button>
                <div style={{ width: 15 }} />
                <Button type={presetRange === "past2weeks" ? "primary" : undefined} onClick={this.onClickPast2Weeks}>
                  Past 2 Weeks
                </Button>
                <div style={{ width: 15 }} />
              </div>
              <RangePicker value={this.state.dateRange} onChange={this.onChangeDateRange} allowClear={false} allowEmpty={false} />
              <div style={styles.total}>Count: {this.state.userReviews.length}</div>
            </div>
          </div>
        </div>
        <div style={styles.tableDesc}>(showing approved reviews only)</div>
        <Table
          columns={cols}
          dataSource={tableData}
          pagination={false}
          loading={this.state.loadingReviews}
          scroll={{
            y: "calc(100vh - 280px)",
          }}
          style={{}}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                this.onClickRow(record);
              },
            };
          }}
          // rowClassName='antd-table-row-clickable'
        />
      </div>
    );

    const booksContent = (
      <div style={styles.tableWrapper}>
        <div style={styles.tableTopWrapper}>
          <div style={styles.tableTopInside}>{titleSection}</div>
        </div>

        <Table
          columns={bookTableCols}
          dataSource={bookTableData}
          pagination={false}
          loading={this.state.loadingBooks}
          scroll={{
            y: "calc(100vh - 80px)",
          }}
          style={{}}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (e) => {
          //       this.onClickBook(record);
          //     },
          //   };
          // }}
          // rowClassName='antd-table-row-clickable'
        />
      </div>
    );

    return (
      <AdminPageTemplate loading={this.state.loading}>
        {this.state.section === "info" ? infoContent : null}
        {this.state.section === "reviews" ? reviewsContent : null}
        {this.state.section === "books" ? booksContent : null}
      </AdminPageTemplate>
    );
  }
}

const styles = {
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    width: 700,
  },
  tableTopWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tableTopInside: {
    width: 700,
  },
  sectionSelect: {
    marginLeft: 40,
  },
  hr: {
    height: 1,
    backgroundColor: colors.lightBG,
    width: "100%",
    marginTop: 25,
    marginBottom: 25,
  },
  title: {
    fontWeight: "700",
    fontSize: 20,
    marginBottom: 20,
  },
  tableBottom: {},
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    overflowY: "auto",
  },
  infoBottom: {
    width: 700,
  },
  tableControlsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  tableButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 40,
  },
  notesDesc: {
    fontSize: 12,
    color: colors.lightText,
  },
  total: {
    marginLeft: 20,
  },
  tableDesc: {
    color: colors.lightText,
    fontSize: 12,
    textAlign: "center",
    width: "100%",
  },
  lightDate: {
    fontSize: 12,
    color: colors.lightText,
    marginLeft: 6,
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});
const mapActionsToProps = {};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(AdminViewUserPage));
