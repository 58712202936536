import React from "react";
import colors from "../../colors";
import Stars from "../../components/Stars";
import linkIcon from "../../assets/img/link.png";
import dayjs from "dayjs";
import { message } from "antd";

const ReviewBox = ({ review = {} }) => {
  let onClickLink = () => {
    if(review.reviewUrl){
      window.open(review.reviewUrl, '_blank')
    }else{
      message.error("No Review URL")
    }
  };

  const dateStr = dayjs((review || {}).reviewDate).format("MMM DD, YYYY")

  return (
    <div style={styles.wrapper}>
      <div style={styles.topRow}>
        <Stars rating={(review || {}).rating} hideIfNone />
        <div style={styles.linkButton} onClick={onClickLink}>
          <img src={linkIcon} style={styles.linkIcon} />
        </div>
      </div>
      <div style={{height: 10}} />
      <div style={styles.topRow}>
        <div style={styles.amazonName}>{(review || {}).amazonUsername}</div>
        <div style={styles.date}>{dateStr}</div>
      </div>
      <div style={styles.reviewText}>{review.reviewText}</div>
    </div>
  );
};

const styles = {
  wrapper: {
    backgroundColor: colors.lightBG,
    borderRadius: 5,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    marginTop: 25,
    width: 450,
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginBottom: 5
  },
  reviewText: {
    marginTop: 12,
    fontWeight: '300'
  },
  amazonName: {
  },
  linkIcon: {
    height: 18,
  },
  linkButton: {

    cursor: "pointer",
  },
  topRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  amazonName: {
    fontWeight: '400'

  },
  date: {
    fontWeight: '400'
  }
};

export default ReviewBox;
