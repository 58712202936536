import * as types from '../types';

export function myUploadsReducer(state = {loading: true, list: []}, action) {
  switch (action.type) {
    case types.SET_MY_UPLOADS:
      return ({
        ...state,
        list: action.payload.list,
        loading: false,
        isFetching: false
      });
      case types.FETCHING_UPLOADS:
        return ({
          ...state,
          loading: (action.payload || {}).silent ? false : true,
          isFetching: true
        });
    default: return state;
  }
}

export function libraryReducer(state = {loading: true, list: []}, action) {
  switch (action.type) {
    case types.SET_LIBRARY:
      return ({
        ...state,
        list: action.payload.list,
        loading: false,
        isFetching: false
      });
      case types.FETCHING_LIBRARY:
        return ({
          ...state,
          loading: (action.payload || {}).silent ? false : true,
          isFetching: true
        });
    default: return state;
  }
}

export function myAssignmentsReducer(state = {loading: true, list: []}, action) {
  switch (action.type) {
    case types.SET_MY_ASSIGNMENTS:
      return ({
        ...state,
        list: action.payload.list,
        completedList: action.payload.completedList,
        loading: false,
        isFetching: false
      });
      case types.FETCHING_ASSIGNMENTS:
        return ({
          ...state,
          loading: (action.payload || {}).silent ? false : true,
          isFetching: true
        });
    default: return state;
  }
}