// books
export const SET_MY_UPLOADS = 'SET_MY_UPLOADS';
export const SET_LIBRARY = 'SET_LIBRARY';
export const SET_MY_ASSIGNMENTS = 'SET_MY_ASSIGNMENTS';

export const FETCHING_UPLOADS = 'FETCHING_UPLOADS';
export const FETCHING_ASSIGNMENTS = 'FETCHING_ASSIGNMENTS';
export const FETCHING_LIBRARY = 'FETCHING_LIBRARY';

// activity
export const SET_MY_ACTIVITY = 'SET_MY_ACTIVITY';
export const FETCHING_ACTIVITY = 'FETCHING_ACTIVITY';

// reviews
// export const SET_MY_ASSIGNMENTS = 'SET_MY_ASSIGNMENTS';

// misc
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const TOGGLE_ACTIVITY_SLIDEOUT = 'TOGGLE_ACTIVITY_SLIDEOUT';
export const SET_POINTS_TRANSACTIONS = 'SET_POINTS_TRANSACTIONS';
export const RESET_STORE = 'RESET_STORE';


