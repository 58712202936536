import { FileOutlined } from '@ant-design/icons';
import React from 'react';
import colors from '../../colors';

const FileBox = ({url, onClickChange}) => {
  return(
    <div style={styles.wrapper}>
      <div style={styles.fileBox} onClick={() => window.open(url, '_blank').focus()}>
        <FileOutlined />
        <div style={styles.label}>View Uploaded File</div>
      </div>
      <div style={styles.change} onClick={onClickChange}>change</div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 3,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.lightBG,
    cursor: 'pointer'
  },
  label: {
    marginLeft: 7
  },
  change: {
    cursor: 'pointer',
    color: colors.main,
    marginLeft: 15
  }
};

export default FileBox;