import { Modal, Input as AntInput, Select, Input, Tooltip } from "antd";
import React, { Component } from "react";
import colors from "../../../colors";
import Points from "../../Points";
import { connect } from 'react-redux';
import api from "../../../firebase/api";
import { getMyUser } from "../../../firebase/users";
import { updateLoggedInUser } from "../../../redux/functions";

class SubmitReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      usernameError: false,
      hasExistingUsername: props.loggedInUser.amazonUsername
    };
  }

  componentDidMount(){
    this.setState({
      username: this.props.loggedInUser.amazonUsername,
    })
  }

  onSubmit = () => {
    if(!this.state.username){
      return this.setState({usernameError: true})
    }
    this.setState({submitting: true})
    api(`/createReview`, {body: {
      assignmentId: this.props.assignment.id,
      amazonUsername: this.state.username,
      messageToAdmin: this.state.messageToAdmin
    }}).then(res => {
      this.setState({submitting: false});
      api(`/scrapeAndSetAmazonReview`, {body:{reviewId: res.review.id}});
      if(!this.state.hasExistingUsername){
        getMyUser().then(res => {
          updateLoggedInUser(res)
        })
      }
      this.onCancel();
      this.props.onSubmitSuccess();
    })
  }

  onChangeUsername = (e) => {
    this.setState({
      username: e.target.value,
      usernameError: e.target.value ? false : this.state.usernameError
    });
  };

  onChangeMessageToAdmin = (e) => {
    this.setState({
      messageToAdmin: e.target.value,
    });
  };
  onCancel = () => {
    if(this.state.usernameError){
      // need to wait for tooltip to close
      this.setState({usernameError: false});
      setTimeout(() => this.props.onCancel(), 100);
    }else{
      this.props.onCancel();
    }
  }

  render() {
    const { visible, onSubmit } = this.props;
    const { hasExistingUsername } = this.state;

    return (
      <Modal
        title="Submit Assignment"
        visible={visible}
        onCancel={this.onCancel}
        okButtonProps={{
          loading: this.state.submitting
        }}
        okText={`Submit Assignment`}
        onOk={this.onSubmit}
      >
        <div>
        Are you sure you have posted your review on Amazon, and are ready to submit this assignment?
          <div style={styles.usernameCol}>
            <div>Your Amazon Review Name:</div>
            <Tooltip placement='right' title={hasExistingUsername ? 'Amazon Username' : 'Please enter your amazon username'} visible={this.state.usernameError}>
              <Input
                style={{width: 250}}
                onChange={this.onChangeUsername}
                value={this.state.username}
                status={this.state.usernameError ? 'error' : undefined}
                disabled={hasExistingUsername}
              />
            </Tooltip>
            <div style={styles.usernameDesc}>You must post your review under this exact name. Once you set your username, it cannot be changed.</div>
            <div style={{height: 20}}></div>
            <div>(Optional) Message To Admin:</div>
            <Input
                // style={{width: 250}}
                onChange={this.onChangeMessageToAdmin}
                value={this.state.messageToAdmin}
                // placeholder={'Optional: message to admin...'}
              />
          </div>

        </div>
      </Modal>
    );
  }
}

const styles = {
  reviewAmountRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  reviewAmountLabel: {
    marginLeft: 8,
  },
  usernameCol: {
    display: "flex",
    flexDirection: 'column',
    marginTop: 12,
  },
  usernameDesc: {
    color: colors.lightText,
    fontSize: 12
  },
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SubmitReviewModal);
