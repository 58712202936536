import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import logo from '../assets/img/logo_header.png';
import colors from '../colors';
import CustomFadeIn from './CustomFadeIn';

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false

    };
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({show: true})
      
    }, 600);
  }




  render() {
    if(!this.state.show) return null;
    return(
        <CustomFadeIn opacityOnly>
      <div style={styles.wrapper}>
          <div style={styles.center}>
            <img src={logo} style={styles.logo} />
            <ClipLoader size={25} color={colors.lightText} />
          </div>
      </div>
        </CustomFadeIn>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 150
  },
  logo: {
    height: 40,
    marginBottom: 20
  }
};

export default SplashScreen;