import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input as AntInput, message } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import EditBookClass from "../../../classes/EditBookClass";
import colors from "../../../colors";
import AdminPageTemplate from "../../../components/AdminPageTemplate";
import Input from "../../../components/inputs/Input";
import PageTitle from "../../../components/PageTitle";
import { adminSubmitBookDecision, getBook } from "../../../firebase/books";
import { adminGetUser } from "../../../firebase/users";
import { withRouter } from "../../../helpers/withRouter";
import CheckItem from "./CheckItem";

class AdminViewNewBookPage extends EditBookClass {
  constructor(props) {
    super(props);
    this.state = {
      book: {
        price: '',
      },
      loading: true,
      user: {},
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    getBook(this.props.params.id).then((b) => {
      this.setState({
        book: { ...b, approved: true, price: '', points: 1000 },
        loading: false,
      });
      adminGetUser(b.userId).then((u) => {
        this.setState({ user: u });
      });
    });
  };

  onChangeNumberOfWords = (e) => {
    let val = e.target.value;
    if (isNaN(val) && val !== undefined && val !== "") return;
    if (!val) val = 0;
    if (/^[0-9]*$/.test(val) === false) return;
    if (isNaN(val) === false && typeof val === "string") val = parseInt(val);
    this.onChangeBook("numberOfWords", val);
  };

  submit = () => {
    const { points } = this.state.book;
    if(!points || points < 1) return alert("You must set a value for the book's points before submitting");
    this.setState({submitting: true});
    adminSubmitBookDecision(this.state.book).then(res => {
      message.success(`Book ${this.state.book.approved ? 'Approved' : 'Rejected'}`);
      this.props.navigate('/admin/newbooks')
    })
  }


  viewUser = (id) => {
    this.props.navigate(`/admin/users/${id}`);
  };

  render() {
    const { book = {}, loading, submitting, user } = this.state;

    return (
      <AdminPageTemplate maxWidth={700} scroll loading={loading}>
        <PageTitle title="View New Book" />
        <div style={{ height: 20 }} />
        <div style={styles.body}>
          <Input inline label="Cover Image">
            <img src={book.coverUrl} style={styles.coverImage} />
          </Input>
          <Input label="Submitted At" inline>
            <div>{dayjs(new Date(book.createdAt)).format("MMM DD, YYYY HH:mm a")}</div>
          </Input>
          <Input label="Title" inline>
            <div>{book.title}</div>
          </Input>
          <Input label="Author" inline>
            <div>{book.author}</div>
          </Input>
          <Input label="User" inline>
            {/* <div>{user.email}</div> */}
            <div>
              <Button size="small" icon={<UserOutlined />} onClick={() => this.viewUser(book.userId)}>
                {user.email}
              </Button>
            </div>
          </Input>
          <Input label="Note to Readers" inline>
            <div>{book.noteToReaders}</div>
          </Input>
          <Input label="Amazon Link" inline>
            <a href={book.amazonUrl} target="_blank" style={{maxWidth: 400, overflowWrap: 'break-word'}}>
              {book.amazonUrl}
            </a>
          </Input>
          <div style={styles.checkItems}>
            <div style={styles.title}>Purchase Options</div>
            <CheckItem
              label={
                <>
                  <span>Standard PDF</span>
                  {book.bookTypeStandard ? 
                    <a href={`${book.pdfUrl}`} style={{ marginLeft: 12 }} target="_blank">
                      View File
                    </a>
                  :null}
                </>
              }
              checked={book.bookTypeStandard}
            />
            <CheckItem label="Kindle Unlimited" checked={book.bookTypeKindle} />
            <CheckItem label="Purchase on Amazon" checked={book.bookTypeVerifiedPurchase} />
          </div>
          <div style={styles.hr} />
          <div style={styles.title}>Admin Actions</div>
          <Input label="Approved?" inline>
            <Checkbox onChange={(e) => this.onChangeBook("approved", e.target.checked)} checked={book.approved} />
          </Input>

          <Input label="Reason" inline>
            <AntInput.TextArea onChange={(e) => this.onChangeBook("approvalReason", e.target.value)} value={book.approvalReason} />
          </Input>

          <Input inline label="# Points" value={book.points || 0} onChange={this.onChangePoints} />
          <Input inline label="# Words" value={book.numberOfWords || 0} onChange={this.onChangeNumberOfWords} />
          <Input inputProps={{type: 'number', disabled: book.bookTypeVerifiedPurchase ? false : true }} inline label="Price" value={book.price} onChange={this.onChangePrice} />

          <div style={{ height: 20 }} />
          <Button type={book.approved ? 'primary' : 'danger'} icon={book.approved ? <CheckOutlined /> : undefined} onClick={this.submit} loading={submitting} disabled={submitting}>
            {book.approved ? 'Approve Book' : 'Reject Book'}
          </Button>
        </div>
      </AdminPageTemplate>
    );
  }
}

const styles = {
  coverImage: {
    height: 250,
  },
  body: {
    flex: 1,
    overflow: "auto",
  },
  title: {
    fontWeight: "600",
    fontSize: 20,
    marginBottom: 5,
  },
  hr: {
    height: 1,
    backgroundColor: colors.lightBG,
    width: "100%",
    marginTop: 25,
    marginBottom: 25,
  },
};

export default withRouter(AdminViewNewBookPage);
