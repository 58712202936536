import React, { Component } from 'react';
import { getMyUploads } from '../firebase/books';
import { getLibrary } from '../firebase/assignmentRequests';
import { setLibrary, setMyAssignments, setMyUploads, setPointsTransactions, updateLoggedInUser } from '../redux/functions';
import { connect } from 'react-redux';
import { getMyActivity } from '../firebase/activity';
import { setMyActivity } from '../redux/functions/activity';
import { getPointsTransactions } from '../firebase/pointsTransactions';
import { getMyAssignments } from '../firebase/assignments';
import { getMyUser } from '../firebase/users';

class DataLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    this.load();
    this.loadInterval = setInterval(() => {
      this.load({silent: true});
    }, 1000*30);
  }

  componentDidUpdate(prevProps, prevState){
    if(!prevProps.loggedInUser.id && this.props.loggedInUser.id){
      this.load();
    }
  }

  componentWillUnmount(){
    if(this.loadInterval) clearInterval(this.loadInterval);
  }

  loadActivity = (opts) => {
    if(!this.props.loggedInUser.id) return;
    if(!this.props.activity.isFetching){
      getMyActivity(opts).then(res => {
        setMyActivity(res);
      })
    }
  }

  load = (opts={}) => {
    if(!this.props.loggedInUser.id) return;

    if(!this.props.myUploads.isFetching){
      getMyUploads(opts).then(res => {
        setMyUploads({list: res.list})
      })
    }

    if(!this.props.library.isFetching){
      getLibrary(opts).then(res => {
        setLibrary(res);
      })
    }

    if(!this.props.myAssignments.isFetching){
      getMyAssignments(opts).then(res => {
        setMyAssignments(res);
      })
    }

    getPointsTransactions(opts).then(res => {
      setPointsTransactions(res)
    })

    this.loadActivity(opts);
    
    if(opts.silent){
      getMyUser().then(res => {
        updateLoggedInUser(res)
      })
    }
    
  }

  render() {
    return null;
  }
}

const styles = {

};

const mapStateToProps = state => ({
  myAssignments: state.myAssignments,
  myUploads: state.myUploads,
  library: state.library,
  loggedInUser: state.loggedInUser,
  activity: state.activity
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(DataLoader);