import React from "react";
import starIcon from "../assets/img/star.png";
import blackStarIcon from "../assets/img/black_star.png";

const Stars = ({ rating = 5, hideIfNone=false }) => {
  
  const starStyle = {
    height: 16,
    width: 16,
    marginRight: 3,
  };

  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    star: {
      ...starStyle,
    },
    blackStar: {
      ...starStyle,
      opacity: 0.13,
    },
  };

  if(hideIfNone && !rating) return null;

  return (
    <div style={styles.wrapper}>
      <img src={rating > 0 ? starIcon : blackStarIcon} style={rating > 0 ? styles.star : styles.blackStar} />
      <img src={rating > 1 ? starIcon : blackStarIcon} style={rating > 1 ? styles.star : styles.blackStar} />
      <img src={rating > 2 ? starIcon : blackStarIcon} style={rating > 2 ? styles.star : styles.blackStar} />
      <img src={rating > 3 ? starIcon : blackStarIcon} style={rating > 3 ? styles.star : styles.blackStar} />
      <img src={rating > 4 ? starIcon : blackStarIcon} style={rating > 4 ? styles.star : styles.blackStar} />
    </div>
  );
};

export default Stars;
