import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import colors from '../colors';

const PendingApproval = ({}) => {
  return(
    <div style={styles.approvalPendingRow}>
    {<div style={styles.warningDot}></div>}
    <div style={styles.pendingApprovalText}>Pending Approval</div>
    <Tooltip title="BookGiveaway must approve your book to be listed on the platform. This should not take long, and is used to prevent spam." color='#191919'>
      <InfoCircleOutlined style={{marginLeft: 8}} />
    </Tooltip>
  </div>
  );
}

const styles = {
  warningDot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: colors.warning,
    marginRight: 6,
  },
  approvalPendingText: {
    fontStyle: "italic",
    color: colors.lightText,
  },
  approvalPendingRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15
  },
  pendingApprovalText: {
    color: colors.text,
    fontStyle: 'italic'
  }
};

export default PendingApproval;