import React, { Component } from "react";
import BookRow from "./BookRow";
import TitleRow from "./TitleRow";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import colors from "../../colors";

const LeftNav = ({}) => {
  let location = useLocation();
  let navigate = useNavigate();
  const myUploads = useSelector((state) => state.myUploads);
  const myAssignments = useSelector((state) => state.myAssignments);

  const onClickNewAssignment = (e) => {
    e.stopPropagation();
    navigate("/library");
  };

  const onClickUploadBook = (e) => {
    e.stopPropagation();
    navigate(`/myuploads/new`);
  };

  const onClickMyUploadItem = (b) => {
    navigate(`/myuploads/${b.id}`);
  };

  const onClickMyAssignmentItem = (a) => {
    navigate(`/assignment/${a.id}`);
  };

  return (
    <div style={styles.wrapper}>
      <TitleRow
        title="My Assignments"
        selected={location.pathname === ("/myassignments")}
        onClick={() => navigate("/myassignments")}
        onClickAdd={onClickNewAssignment}
      />
      {myAssignments.list.map((a) => {
        return <BookRow book={a.book} id={a.id} onClick={() => onClickMyAssignmentItem(a)}/>;
      })}
      {myAssignments.list.length === 0 && myAssignments.loading === false ? <div style={styles.none}>none</div> : null}

      <div style={{ height: 30 }} />

      <TitleRow
        title="My Uploads"
        selected={location.pathname === ("/myuploads")}
        onClick={() => navigate("/myuploads")}
        onClickAdd={onClickUploadBook}
      />
      {myUploads.list.map((b) => {
        return <BookRow book={b} onClick={() => onClickMyUploadItem(b)}/>;
      })}
  
      {myUploads.list.length === 0 && myUploads.loading === false ? <div style={styles.none}>none</div> : null}
    </div>
  );
};

const styles = {
  wrapper: {
    height: "100vh",
    // position: 'fixed',
    width: 250,
    backgroundColor: "#f7f7f7",
    padding: 8,
    paddingTop: 75,
    position: "fixed",
    top: 0,
    left: 0,
    paddingTop: 70,
    zIndex: 2,
    overflowY: 'auto'
  },
  none: {
    color: colors.lightText,
    fontStyle: "italic",
    textAlign: 'center'
  },
};

export default LeftNav;
