import { Alert, Button, message } from "antd";
import React, { Component } from "react";
import BookBox from "../../components/BookBox";
import PageTemplate from "../../components/PageTemplate";
import PageTitle from "../../components/PageTitle";
import api from "../../firebase/api";
import { getAssignmentRequest } from "../../firebase/assignmentRequests";
import { getBook } from "../../firebase/books";
import { withRouter } from "../../helpers/withRouter";
import BookDescription from "../ViewAssignmentPage/BookDescription";

class ViewBookPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book: {},
      loading: true,
      error: null,
      assignmentRequest: {}
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let id = this.props.params.id;
    let r = await getAssignmentRequest(id);
    getBook(r.bookId).then(res => {
      this.setState({book: res, assignmentRequest: r, loading: false})
    })
  };

  onClickAdd = () => {
    this.setState({adding: true, error: null});
    api('/readBook', {
      body: {
        // bookId: this.state.book.id,
        assignmentRequestId: this.props.params.id
      }
    }).then(res => {
      if(res.status === 'success'){
        this.props.navigate(`/myassignments`)
      }else{
        this.setState({
          error: res.message,
          adding: false
        });
      }
    })
  }

  render() {
    const { book, adding, error } = this.state;

    if(!book.messageToReaders) book.messageToReaders = 'We hope you enjoy the book!';
    return (
      <PageTemplate loading={this.state.loading}>
        <PageTitle title="View Book" loading={this.state.loading}/>
        <div style={styles.pageTop}>
          <BookBox book={this.state.book} />
          {book.messageToReaders ? 
            <BookDescription
              description={book.messageToReaders}
              authorName={book.author}
            />
          :<div />}
        </div>
        <div style={styles.bottom}>
          <Button style={{width: 250, marginTop: 50}} size='large' type="primary" onClick={this.onClickAdd} loading={adding}>+ Add to My Assignments</Button>
          {error ? 
          <Alert style={{marginTop: 20}} type='error' showIcon message={error} />
          :null}
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  pageTop: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 20,
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }
};

export default withRouter(ViewBookPage);
