import { Checkbox } from 'antd';
import React from 'react';
import colors from '../../colors';

const BookTypeRow = ({title, desc, extra, value, onChange}) => {
  return(
    <div style={styles.bookTypeRow}>
    <div style={styles.bookTypeLeft}>
      <Checkbox checked={value} onChange={e => onChange(e.target.checked)}></Checkbox>
    </div>
    <div style={styles.bookTypeRight}>
      <div style={styles.title}>{title}</div>
      <div style={styles.desc}>{desc}</div>
      {extra ? extra : null}

    </div>
  </div>
  );
}

const styles = {
  bookTypeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 17
  },
  bookTypeLeft: {
    width: 50,
    // paddingTop: 5
  },
  title: {
    // fontSize: 20,
    fontWeight: '600'
  },
  desc: {
    color: colors.lightText
  }
};

export default BookTypeRow;